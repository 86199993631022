import { Component, HostListener, ElementRef, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { Store, select } from '@ngrx/store'
import { HideBookNavigationAction } from '../redux/actions/book-navigation.action'
import { filter, distinctUntilChanged } from 'rxjs/operators'
import { Router, NavigationStart } from '@angular/router'
import { BookState } from '../redux/book.state'
import { isEqual } from 'lodash'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'book-navigation',
    templateUrl: './book-navigation.component.html',
})
export class BookNavigationComponent implements OnInit {

    public bookNavigation: Observable<any>
    public isCollapsed = true

    constructor(private readonly store: Store<BookState>, private readonly elementRef: ElementRef, private readonly router: Router) {
    }

    ngOnInit() {
        this.bookNavigation = this.store.pipe(select('bookNavigation'), distinctUntilChanged((oldVal, newVal) => isEqual(oldVal, newVal)))

        this.bookNavigation
            .pipe(untilDestroyed(this))
            .subscribe(({ collapsed }) => this.isCollapsed = collapsed)

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                untilDestroyed(this)
            )
            .subscribe(() => this.store.dispatch(new HideBookNavigationAction()))
    }

    @HostListener('document:click', ['$event', '$event.target'])
    public onClick(_event: MouseEvent, targetElement: HTMLElement): void {
        if (!targetElement || this.isCollapsed) {
            return
        }

        const isToggler = (targetElement.parentElement && targetElement.parentElement.classList.contains('book-navigation-toggler')) ||
            targetElement.classList.contains('book-navigation-toggler')
        const isOpenButton = targetElement.classList.contains('open-book-navigation')
        const clickedInside = this.elementRef.nativeElement.contains(targetElement) || isToggler || isOpenButton
        if (!clickedInside) {
            this.store.dispatch(new HideBookNavigationAction())
        }
    }

}
