import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'

import { JhipsterSharedModule } from '../../shared'
import { HttpClientModule } from '@angular/common/http'
import {
    RoomBookService,
    BookNavigationComponent
} from '.'
import { RoomBookMenuComponent } from './room-book/room-book-menu.component'
import { RouterModule } from '@angular/router'
import { TableOfContentsComponent } from './table-of-contents/table-of-contents.component'
import { TableOfContentsService } from './table-of-contents/table-of-contents.service'
import { TabsComponent } from './tabs/tabs.component'

@NgModule({
    imports: [
        JhipsterSharedModule,
        HttpClientModule,
        RouterModule
    ],
    declarations: [
        BookNavigationComponent,
        RoomBookMenuComponent,
        TableOfContentsComponent,
        TabsComponent
    ],
    entryComponents: [
        BookNavigationComponent
    ],
    exports: [
        BookNavigationComponent,
        TabsComponent
    ],
    providers: [
        RoomBookService,
        TableOfContentsService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BookNavigationModule {}
