import { Component, OnDestroy, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { JhiAlertService, JhiEventManager } from 'ng-jhipster'
import { Subscription } from 'rxjs'
import { ProfileService } from '../../layouts/profiles/profile.service'
import { NotificationConfig } from '../config/notification/notification.config'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'jhi-alert-error',
    template: `
        <div class="alerts" role="alert">
            <div *ngFor="let alert of alerts" [ngClass]="{\'alert.position\': true, \'toast\': alert.toast}">
                <ngb-alert *ngIf="alert && alert.type && alert.msg" [type]="alert.type" (close)="alert.close(alerts)">
                    <pre class="alert-message" [innerHTML]="alert.msg"></pre>
                    <a *ngIf="!this.inProduction" class="report-anchor" (click)="sendReport(alert.msg)">
                        Report
                    </a>
                </ngb-alert>
            </div>
        </div>`,
    styles: [
        '.alert-message { display: inline}',
        '.report-anchor { float: right; color: #ffffff; text-decoration: underline}'
    ]
})
export class JhiAlertErrorComponent implements OnInit, OnDestroy {

    alerts: any[]
    cleanHttpErrorListener: Subscription

    public inProduction: boolean
    private latestHttpError

    // tslint:disable-next-line: no-unused-variable
    constructor(private readonly alertService: JhiAlertService,
                private readonly eventManager: JhiEventManager,
                private readonly translateService: TranslateService,
                private readonly profileService: ProfileService,
                private readonly notificationConfig: NotificationConfig) {
        this.alerts = []

        this.cleanHttpErrorListener = eventManager.subscribe('kstandards.httpError', (response) => {
            let i
            this.latestHttpError = response.content.error
            const httpErrorResponse = response.content
            switch (httpErrorResponse.status) {
                // connection refused, server not reachable
                case 0:
                    this.addErrorAlert('Server not reachable', 'error.server.not.reachable')
                    break

                case 400:
                    const arr = httpErrorResponse.headers.keys()
                    let errorHeader = null
                    let entityKey = null
                    arr.forEach((entry) => {
                        if (entry.endsWith('app-error')) {
                            errorHeader = httpErrorResponse.headers.get(entry)
                        } else if (entry.endsWith('app-params')) {
                            entityKey = httpErrorResponse.headers.get(entry)
                        }
                    })
                    if (errorHeader) {
                        const entityName = this.translateService.instant('global.menu.entities.' + entityKey)
                        this.addErrorAlert(errorHeader, errorHeader, {entityName})
                    } else if (httpErrorResponse.error !== '' && httpErrorResponse.error.fieldErrors) {
                        const fieldErrors = httpErrorResponse.error.fieldErrors
                        for (i = 0; i < fieldErrors.length; i++) {
                            const fieldError = fieldErrors[i]
                            // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                            const convertedField = fieldError.field.replace(/\[\d*\]/g, '[]')
                            const fieldName = this.translateService.instant('kstandards.' +
                                fieldError.objectName.replace('DTO', '') + '.' + convertedField)
                            this.addErrorAlert(
                                'Error on field "' + fieldName + '"', 'error.' + fieldError.message, {fieldName})
                        }
                    } else if (httpErrorResponse.error !== '' && httpErrorResponse.error.message) {
                        this.addErrorAlert(httpErrorResponse.error.message, httpErrorResponse.error.message, httpErrorResponse.error.params)
                    } else {
                        this.addErrorAlert(httpErrorResponse.error)
                    }
                    break

                case 404:
                    this.addErrorAlert('Not found', 'error.url.not.found')
                    break

                default:
                    if (httpErrorResponse.error !== '' && httpErrorResponse.error.message) {
                        this.addErrorAlert(httpErrorResponse.error.message)
                    } else {
                        this.addErrorAlert(httpErrorResponse.error)
                    }
            }
        })
    }

    ngOnInit(): void {
        this.profileService.getProfileInfo()
            .pipe(
                untilDestroyed(this)
            ).subscribe((profileInfo) => {
            this.inProduction = profileInfo.inProduction
        })
    }

    ngOnDestroy() {
        if (this.cleanHttpErrorListener !== undefined && this.cleanHttpErrorListener !== null) {
            this.eventManager.destroy(this.cleanHttpErrorListener)
            this.alerts = []
        }
    }

    addErrorAlert(message, key?, data?) {
        key = (key && key !== null) ? key : message
        this.alerts.push(
            this.alertService.addAlert(
                {
                    type: 'danger',
                    msg: key,
                    params: data,
                    timeout: 10000,
                    toast: this.alertService.isToast(),
                    scoped: true
                },
                this.alerts
            )
        )
    }

    async sendReport(message: string) {
        const configuration = await this.notificationConfig.loadConfigurationFile()
        const now = new Date()
        location.href = `mailto:${configuration.recipients.join(';')}
            ?subject=${this.latestHttpError.title ? this.latestHttpError.title : 'Error Report'}
            &body=Error Report %0A
            Status: ${this.latestHttpError.status} %0A
            Path: ${this.latestHttpError.path} %0A
            Message: ${message} %0A
            Details: ${this.latestHttpError.detail ? this.latestHttpError.detail : 'N/A'} %0A
            Timestamp: ${now.toUTCString()}`
    }
}
