import { ReleaseState } from '../state/release.state'
import { ReleaseAction, ReleaseActionType, SetReleasesPayload, SetReferenceReleasePayload } from '../actions/release.action'
import { keyBy } from 'lodash'

const initialState: ReleaseState = {
    releases: {
        loaded: false,
        data: []
    },
    currentRelease: null,
    selectedReferenceRelease: {
        loaded: false,
        release: undefined
    },
    showSidebar: true,
    gallery: [],
    pdfs: {
        loaded: false,
        data: {}
    }
}

export function releaseReducer(state = initialState, action: ReleaseAction): ReleaseState {
    switch (action.type) {
        case ReleaseActionType.SET_RELEASES:
            const payload = action.payload as SetReleasesPayload

            const newState = {
                ...state,
                releases: { loaded: true, data: payload.releases },
            }

            // If there is no reference release yet, the ReleaseContext has none configured. In this case, we default to the previous release
            if (!state.selectedReferenceRelease.loaded) {
                // The list of releases is sorted in descending order by default. This will find the previous release.
                const referenceRelease = payload.releases.find((release) => release.id !== payload.currentReleaseId)
                newState.selectedReferenceRelease = {
                    loaded: true,
                    release: referenceRelease || null
                }
            }

            if (payload.updateCurrentRelease) {
                newState.currentRelease = payload.releases.find((release) => release.id === payload.currentReleaseId)
            }

            return newState
        case ReleaseActionType.SET_REFERENCE:
            const refPayload = action.payload as SetReferenceReleasePayload

            return {
                ...state,
                selectedReferenceRelease: {
                    loaded: true,
                    release: refPayload
                }
            }
        case ReleaseActionType.TOGGLE_SIDEBAR:
            return { ...state, showSidebar: !state.showSidebar }
        case ReleaseActionType.CLEAR_RELEASE_DATA:
            return { ...initialState, showSidebar: state.showSidebar }
        case ReleaseActionType.SET_CURRENT_RELEASE: {
            return { ...state, currentRelease: action.payload }
        }
        case ReleaseActionType.SET_GALLERY_DATA:
            return { ...state, gallery: action.payload }
        case ReleaseActionType.SET_PDF_DATA:
            return { ...state, pdfs: {loaded: true, data: keyBy(action.payload, 'checksum')} }
        default:
            return state
    }
}
