import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'replaceDiffMarkers'
})
export class ReplaceDiffMarkersPipe implements PipeTransform {

    transform(value: any, ...args: any[]) {

        if (!value) {
            return ''
        }

        return value
            .replace(/{\+/g, `<ins>`)
            .replace(/\+}/g, '</ins>')
    }
}
