import { Routes } from '@angular/router'

import { LegalNoticeComponent } from '.'

export const legalNoticeRoutes: Routes = [
    {
        path: 'legal-notice',
        component: LegalNoticeComponent,
        data: {
            authorities: [],
            pageTitle: 'kstandards.legalNotice.pageTitle'
        }
    }
]
