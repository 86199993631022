import { Routes } from '@angular/router'

import { UserRouteAccessService } from '../../shared'
import { Roles } from '../../shared/auth/roles'
import { LandingPageComponent } from './landing-page.component'

export const landingPageRoutes: Routes = [
    {
        path: '',
        component: LandingPageComponent,
        data: {
            authorities: Roles.VIEWER
        },
        canActivate: [UserRouteAccessService]
    }
]
