import { Component, OnInit } from '@angular/core'
import { VersionService } from './version.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'jhi-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

    buildVersion: string

    constructor(private readonly versionService: VersionService) {
    }

    ngOnInit(): void {
        this.versionService.getVersion().pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.buildVersion = data?.project?.version
            })
    }
}
