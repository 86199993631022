import { Routes } from '@angular/router'

import { PageViewsReleasesComponent } from '.'
import { UserRouteAccessService } from '../shared/auth/user-route-access-service'

export const pageViewReleasesRoutes: Routes = [
    {
        path: 'page-views-releases/:id',
        component: PageViewsReleasesComponent,
        data: {
            authorities: [],
            pageTitle: 'kstandards.home.pageTitle'
        },
        canActivate: [UserRouteAccessService]
    }
]
