import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Country } from '../entities/country.model'

@Injectable()
export class CountryService {

    constructor(private readonly http: HttpClient) {
    }

    query(): Observable<Country[]> {
        return this.http.get<Country[]>(`/api/countries`)
    }
}
