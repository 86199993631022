import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient, HttpParams } from '@angular/common/http'
import { ReleaseViewStats } from '../entities'

@Injectable()
export class PageViewsReleasesService {

    constructor(private readonly http: HttpClient) {
    }

    queryDetails(releaseId: Number): Observable<ReleaseViewStats> {
        const params = new HttpParams().set('releaseId', String(releaseId))
        return this.http.get<ReleaseViewStats>(`/api/release-view-statistics`, { params })
    }
}
