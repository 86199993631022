import { Component } from '@angular/core'
import { ProfileService } from '../layouts/profiles/profile.service'

@Component({
    selector: 'legal-notice',
    templateUrl: 'legal-notice.component.html'
})
export class LegalNoticeComponent {
    public isLidl: boolean

    constructor(private readonly profileService: ProfileService) {
        this.profileService.getProfileInfo().subscribe((profileInfo) => {
            this.isLidl = profileInfo.lidl
        })
    }
}
