import { Routes } from '@angular/router'

import { HomeComponent } from '.'
import { UserRouteAccessService } from '../shared/auth/user-route-access-service'

export const homeRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
        data: {
            authorities: [],
        },
        canActivate: [UserRouteAccessService]
    }
]
