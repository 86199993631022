import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { SERVER_API_URL } from '../../app.constants'
import { Manufacturer } from '../../entities/manufacturer.model'

@Injectable()
export class ManufacturerService {

    constructor(private readonly httpClient: HttpClient) {
    }

    find(releaseId: number, id: number, selectedReleaseId?: number): Observable<Manufacturer> {
        let params = new HttpParams()
        if (selectedReleaseId) {
            params = params.append('originalReleaseId', '' + selectedReleaseId)
        }

        return this.httpClient
            .get<Manufacturer>(`${SERVER_API_URL}/api/releases/${releaseId}/manufacturers/${id}`, { params })
            .pipe(map((manufacturer) => Object.assign(new Manufacturer(), manufacturer)))
    }
}
