import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { RouterModule } from '@angular/router'

import { JhipsterSharedModule } from '../../shared'
import { HttpClientModule } from '@angular/common/http'

import { LandingPageComponent } from './landing-page.component'
import { landingPageRoutes } from './landing-page.route'

const ENTITY_STATES = [
    ...landingPageRoutes
]

@NgModule({
    imports: [
        JhipsterSharedModule,
        RouterModule.forChild(ENTITY_STATES),
        HttpClientModule
    ],
    declarations: [
        LandingPageComponent
    ],
    entryComponents: [
        LandingPageComponent
    ],
    exports: [
        LandingPageComponent
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LandingPageModule {}
