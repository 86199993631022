
import { BookNavigationMode } from '../../navigation/book-navigation-mode'
import { BookNavigationActionType, BookNavigationAction, SelectRoombookAction } from '../actions/book-navigation.action'
import { BookNavigationState } from '../state/book-navigation.state'

const localStorageValue = localStorage.getItem('bookNavigationMode') as BookNavigationMode

const initialState: BookNavigationState = {
    roombook: {
        loaded: false,
        data: []
    },
    tableOfContents: {
        loaded: false,
        data: []
    },
    attachmentCategories: {
        loaded: false,
        data: []
    },
    mode: localStorageValue || BookNavigationMode.ROOMBOOK,
    selectedRoom: null,
    collapsed: true
}

export function bookNavigationReducer(state = initialState, action: BookNavigationAction) {
    switch (action.type) {
        case BookNavigationActionType.SELECT_ROOMBOOK:
            return {
                ...state,
                mode: BookNavigationMode.ROOMBOOK,
                selectedRoom: (<SelectRoombookAction>action).payload,
            }
        case BookNavigationActionType.SELECT_TABLE_OF_CONTENTS:
            return {
                ...state,
                mode: BookNavigationMode.TABLE_OF_CONTENTS,
                selectedRoom: null,
            }
        case BookNavigationActionType.TOGGLE_BOOK_NAVIGATION:
            return {
                ...state,
                collapsed: !state.collapsed,
            }
        case BookNavigationActionType.HIDE_BOOK_NAVIGATION:
            if (state.collapsed) {
                return state
            }

            return {
                ...state,
                collapsed: true,
            }
        case BookNavigationActionType.SHOW_BOOK_NAVIGATION:
            return {
                ...state,
                collapsed: false,
            }
        case BookNavigationActionType.SET_ROOMBOOK_DATA:
            return {
                ...state,
                roombook: {
                    loaded: true,
                    data: action.payload
                }
            }
        case BookNavigationActionType.SET_TABLE_OF_CONTENTS_DATA:
            return {
                ...state,
                tableOfContents: {
                    loaded: true,
                    data: action.payload
                }
            }
        case BookNavigationActionType.SET_ATTACHMENT_CATEGORY_DATA:
            return {
                ...state,
                attachmentCategories: {
                    loaded: true,
                    data: action.payload
                }
            }
        default:
            return state
    }
}
