import { MaterialGroup } from '.'
import { BaseEntity } from '../shared/model/base-entity'
import { Manufacturer } from './manufacturer.model'
import { Material } from './material.model'
import { ProductGroup } from './product-group.model'

export class Product implements BaseEntity {
    constructor(public id?: number,
                public originalId?: number,
                public name?: string,
                public series?: string,
                public color?: string,
                public measurement?: string,
                public slipResistance?: string,
                public articleDescription?: string,
                public relationType?: string,
                public description?: string,
                public manufacturer?: Manufacturer,
                public productGroup?: ProductGroup,
                public hasChanges?: boolean,
                public materials?: Material[],
                public materialGroups?: MaterialGroup[]) {
    }
}
