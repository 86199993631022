import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'

import { RouterModule } from '@angular/router'
import { NotAuthorizedComponent } from './not-authorized.component'
import { notAuthorizedRoute } from './not-authorized.route'
import { JhipsterSharedModule } from '../shared/shared.module'

const ROUTES = [
    ...notAuthorizedRoute
]

@NgModule({
    imports: [
        JhipsterSharedModule,
        RouterModule.forChild(ROUTES)
    ],
    declarations: [NotAuthorizedComponent],
    providers: [],
    exports: [NotAuthorizedComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class NotAuthorizedModule {
}
