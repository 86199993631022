import { Injectable } from '@angular/core'
import { JhiAlertService } from 'ng-jhipster'

@Injectable()
export class CopyToClipboardHelper {

    constructor(private readonly jhiAlertService: JhiAlertService) { }

    copyToClipboard(text: string) {
        const textArea = document.createElement('textarea')

        // Hide the textarea from actually showing
        textArea.style.position = 'fixed'
        textArea.style.top = '-999px'
        textArea.style.left = '-999px'
        textArea.style.width = '2em'
        textArea.style.height = '2em'
        textArea.style.padding = '0'
        textArea.style.border = 'none'
        textArea.style.outline = 'none'
        textArea.style.boxShadow = 'none'
        textArea.style.background = 'transparent'

        // Set the textarea's content to our value defined in our [text-copy] attribute
        textArea.value = text
        document.body.appendChild(textArea)

        // This will select the textarea
        textArea.select()

        try {
            // Most modern browsers support execCommand('copy'|'cut'|'paste'), if it doesn't it should throw an error
            const successful = document.execCommand('copy')
            const msg = successful ? 'successful' : 'unsuccessful'
            // Let the user know the text has been copied, e.g toast, alert etc.
            console.log(msg)
        } catch (err) {
            // Tell the user copying is not supported and give alternative, e.g alert window with the text to copy
            console.log('unable to copy')
        }

        // Finally we remove the textarea from the DOM
        document.body.removeChild(textArea)
        this.jhiAlertService.success('global.copyToClipboard.copySuccessAlertMessage', 'user')
    }
}
