import { Routes } from '@angular/router'

import { UserRouteAccessService } from '../../shared'
import { RoomDetailComponent } from './room-detail.component'
import { Roles } from '../../shared/auth/roles'

export const roomRoutes: Routes = [
    {
        path: 'room/:id',
        component: RoomDetailComponent,
        data: {
            authorities: Roles.VIEWER
        },
        canActivate: [UserRouteAccessService]
    }
]
