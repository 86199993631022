import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'
import { Craft } from '../../entities/craft.model'
import { map } from 'rxjs/operators'

@Injectable()
export class CraftService {

    constructor(private readonly httpClient: HttpClient) {
    }

    find(releaseId: number, id: number, selectedReleaseId?: number): Observable<Craft> {
        let params = new HttpParams()
        if (selectedReleaseId) {
            params = params.append('originalReleaseId', '' + selectedReleaseId)
        }

        return this.httpClient
            .get<Craft>(`${SERVER_API_URL}/api/releases/${releaseId}/crafts/${id}`, { params })
            .pipe(map((craft) => Object.assign(new Craft(), craft)))
    }
}
