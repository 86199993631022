import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { pluck } from 'rxjs/operators'
import { ReleaseContext } from '../entities/release-context.model'
import { Release } from '../entities/release.model'

@Injectable()
export class ReleaseService {

    constructor(private readonly http: HttpClient) {
    }

    public findReleaseContextByUrlFragments(urlFragment: string, language: string, constructionText: string, ignoreErrors = false): Observable<ReleaseContext> {
        const construction: boolean = constructionText === 'construction'
        let headers = new HttpHeaders()

        if (ignoreErrors) {
            headers = headers.append('X-Ignore-Errors', '1')
        }

        return this.http
            .get<ReleaseContext>(`/api/release-contexts/${urlFragment}/${language}/${construction}`, {headers})
    }

    public getLatestReleaseId(urlFragment: string, language: string, constructionText: string): Observable<number> {
        return this.findReleaseContextByUrlFragments(urlFragment, language, constructionText, true).pipe(pluck<ReleaseContext, number>('latestPublishedRelease', 'id'))
    }

    public query(): Observable<ReleaseContext[]> {
        return this.http.get<ReleaseContext[]>(`/api/release-contexts`)
    }

    public findReleaseById(releaseId: number): Observable<Release> {
        const headers = new HttpHeaders().append('X-Ignore-Errors', '1')
        return this.http.get<Release>(`/api/releases/${releaseId}`, {headers})
    }

    public findAllReleasesByReleaseContextIdOrReleaseId(releaseContextId: number, releaseId: number): Observable<Release[]> {
        return this.http.get<Release[]>(`/api/releases/release-context/${releaseContextId}/release/${releaseId}`)
    }
}
