import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { SERVER_API_URL } from '../../app.constants'
import { Product } from '../../entities/product.model'

@Injectable()
export class ProductService {

    constructor(private readonly httpClient: HttpClient) {
    }

    find(releaseId: number, id: number): Observable<Product> {
        return this.httpClient
            .get<Product>(`${SERVER_API_URL}/api/releases/${releaseId}/products/${id}`)
            .pipe(map((product) => Object.assign(new Product(), product)))
    }
}
