import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { MaterialModule } from './material/material.module'
import { RouterModule } from '@angular/router'
import { bookRoutes } from './book.route'
import { BookNavigationModule } from './navigation'
import { JhipsterSharedModule } from '../shared'
import { SearchResultsComponent } from './search-results/search-results.component'
import { ReleaseComponent } from './release.component'
import { ReleaseService } from './release.service'
import { CraftModule } from './craft/craft.module'
import { SearchService } from './search-results/search.service'
import { RoomModule } from './room/room.module'
import { MaterialGroupModule } from './material-group/material-group.module'
import { AttachmentCategoryModule } from './attachment-category/attachment-category.module'
import { LandingPageModule } from './landing-page'
import { NgProgressModule } from '@ngx-progressbar/core'
import { ChangelogModule } from './changelog'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { RevitModule } from './revit/revit.module'
import { ManufacturerModule } from './manufacturer/manufacturer.module'
import { ProductModule } from './product/product.module'

@NgModule({
    imports: [
        AttachmentCategoryModule,
        MaterialModule,
        CraftModule,
        RoomModule,
        MaterialGroupModule,
        BookNavigationModule,
        LandingPageModule,
        ChangelogModule,
        JhipsterSharedModule,
        RevitModule,
        ManufacturerModule,
        ProductModule,
        RouterModule.forChild(bookRoutes),
        NgProgressModule,
        NgbModule
    ],
    declarations: [
        ReleaseComponent,
        SearchResultsComponent
    ],
    entryComponents: [
    ],
    providers: [
        ReleaseService,
        SearchService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BookModule {
}
