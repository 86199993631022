import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { RouterModule } from '@angular/router'

import { JhipsterSharedModule } from '../../shared'
import { HttpClientModule } from '@angular/common/http'
import { changelogRoutes } from './changelog.route'
import { ChangelogComponent } from './changelog.component'

const ENTITY_STATES = [
    ...changelogRoutes
]

@NgModule({
    imports: [
        JhipsterSharedModule,
        RouterModule.forChild(ENTITY_STATES),
        HttpClientModule
    ],
    declarations: [
        ChangelogComponent
    ],
    entryComponents: [
        ChangelogComponent
    ],
    exports: [
        ChangelogComponent
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ChangelogModule {}
