import { MaterialSubGroup } from './material-sub-group.model'
import { ContentContainer } from './content-container.model'
import { FileContext } from './file-context.model'
import { Product } from './product.model'
import { Craft } from '.'
import { AttachmentCategory } from './attachment-category.model'
import { flatMap } from 'lodash'
import { MaterialCategory } from './material-category.model'

export class MaterialGroup implements ContentContainer {
    constructor(public id?: number,
        public originalId?: number,
        public orderId?: number,
        public heading?: string,
        public textContent?: string,
        public linkedAttachmentCategories?: AttachmentCategory[],
        public fileContexts?: FileContext[],
        public materialSubGroups?: MaterialSubGroup[],
        public materialCategories?: MaterialCategory[],
        public products?: Product[],
        public parentCraft?: Craft) {
        if (!this.fileContexts) {
            this.fileContexts = []
        }
        if (!this.linkedAttachmentCategories) {
            this.linkedAttachmentCategories = []
        }
        if (!this.materialSubGroups) {
            this.materialSubGroups = []
        }
        if (!this.products) {
            this.products = []
        }
        if (!this.materialCategories) {
            this.materialCategories = []
        }
    }

    // For material groups, we need to collect the files of all sub groups

    get allFiles(): FileContext[] {

        const localFileContexts = this.fileContexts ? this.fileContexts : []

        const childFileContexts = flatMap(this.materialSubGroups, (msg) => msg.fileContexts)
        return [...localFileContexts, ...childFileContexts]
    }

    get images(): FileContext[] {
        return this.allFiles.filter((fileContext) => fileContext.file.fileType.startsWith('image/'))
    }

    get pdfs(): FileContext[] {
        return this.allFiles.filter((fileContext) => fileContext.file.fileType.startsWith('application/pdf'))
    }

    get files(): FileContext[] {
        return this.allFiles.filter((fileContext) => !(fileContext.file.fileType.startsWith('application/pdf') || fileContext.file.fileType.startsWith('image/')))
    }
}
