import { Injectable } from '@angular/core'
import { ContentContainer } from '../../entities/content-container.model'
import { flatMap, concat } from 'lodash'

@Injectable({ providedIn: 'root' })
export class PdfCollectorService {

    collectPdfs(contentContainer: ContentContainer) {
        const attachmentFiles =
            flatMap(contentContainer.linkedAttachmentCategories,
                (attachmentCategory) => flatMap(attachmentCategory.attachments,
                    (attachment) => flatMap(attachment.fileContexts, (fileContext) => fileContext.file)
                )
            )
        const embeddedFiles = contentContainer.fileContexts.map((fc) => fc.file)

        const embeddedMaterialSubGroupsFiles = flatMap(contentContainer.materialSubGroups,
            (subGroup) => flatMap(subGroup.fileContexts,
                (fileContext) => fileContext.file))

        return concat(attachmentFiles, embeddedFiles, embeddedMaterialSubGroupsFiles).filter((file) => file.fileType === 'application/pdf')
    }

}
