import { Component, Input, OnInit } from '@angular/core'
import { Params } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { File } from '../../../entities'
import { BookState } from '../../redux/book.state'
import { selectQueryParams } from '../../redux/state'

@Component({
    selector: 'simple-file-list',
    templateUrl: 'simple-file-list.component.html',
    styleUrls: ['simple-file-list.component.scss']
})
export class SimpleFileListComponent implements OnInit {
    @Input()
    files: File[]

    queryParams$: Observable<Params>

    constructor(private readonly store: Store<BookState>) { }

    ngOnInit() {
        this.queryParams$ = this.store.pipe(select(selectQueryParams))
    }

    trackChecksum(file: File) {
        return file.checksum
    }
}
