import { Component, OnInit } from '@angular/core'
import { RoomGroup } from '../../../entities/room-group.model'
import { delayWhen, filter, map } from 'rxjs/operators'
import { Subject, ReplaySubject } from 'rxjs'
import { Store, select } from '@ngrx/store'
import { Room } from '../../../entities'
import { BookState } from '../../redux/book.state'
import { getBookNavigationState, getRoombookData } from '../../redux/state/book-navigation.state'
import { selectQueryParams } from '../../redux/state'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'room-book-menu',
    templateUrl: './room-book-menu.component.html',
    styleUrls: ['./room-book-menu.component.scss']
})
export class RoomBookMenuComponent implements OnInit {

    private readonly hasLoadedRoombookData: Subject<boolean> = new ReplaySubject<boolean>(1)

    public roomGroups: RoomGroup[]

    public queryParams = {}

    constructor(
        private readonly store: Store<BookState>
    ) {

    }

    ngOnInit(): void {
        this.store
            .pipe(
                select(getBookNavigationState),
                filter(({ selectedRoom }) => !!selectedRoom),
                delayWhen(() => this.hasLoadedRoombookData)
            )
            .subscribe(({ selectedRoom }) => {
                this.searchRoom(selectedRoom)
            })

        this.store
            .pipe(
                select(getRoombookData),
                filter((roombookPartialState) => roombookPartialState.loaded),
                map((roombookPartialState) => roombookPartialState.data),
                untilDestroyed(this)
            )
            .subscribe(
                (roomGroups: RoomGroup[]) => {
                    this.roomGroups = roomGroups
                    this.hasLoadedRoombookData.next(true)
                }
            )
        this.store
            .pipe(
                select(selectQueryParams),
                untilDestroyed(this)
            )
            .subscribe((params) => {
                this.queryParams = params
            })
    }

    public searchRoom(searchedRoom: Room) {

        // Find room group
        const roomGroup = this.roomGroups.find((currentRoomGroup) => currentRoomGroup.rooms.some((room) => room.originalId === searchedRoom.originalId))

        if (roomGroup) {
            this.expand(roomGroup)
        }

    }

    public toggle(roomGroup: RoomGroup) {
        this.hideRoomGroupsExcept(roomGroup)

        roomGroup.isCollapsed = !roomGroup.isCollapsed

    }

    public expand(roomGroup: RoomGroup) {
        this.hideRoomGroupsExcept(roomGroup)

        roomGroup.isCollapsed = false

    }

    private hideRoomGroupsExcept(roomGroup) {
        for (const currentRoomGroup of this.roomGroups) {

            if (currentRoomGroup.originalId === roomGroup.originalId) {
                continue
            }

            currentRoomGroup.isCollapsed = true
        }
    }

}
