export enum HostCompanyIdEnum {
    KStandards = 'baustandards',
    LStandards = 'lidl-baustandards',
}

export enum RoleIdEnum {
    Viewer = 'viewer',
    Admin = 'admin',
    Extern = 'extern',
    Intern = 'intern',
}
export class Roles {
    private static ROLE_SUPERADMIN = ['baustandards-superadmin', 'lidl-baustandards-superadmin-int']
    private static ROLE_ADMIN = ['baustandards-admin', 'lidl-baustandards-admin-int']
    private static ROLE_PUBLISHER = ['baustandards-publisher']
    private static ROLE_EDITOR = ['baustandards-editor', 'lidl-baustandards-editor-int']
    private static ROLE_MONITORING = ['baustandards-monitoring', 'lidl-baustandards-monitoring-int']
    private static ROLE_VIEWER = ['baustandards-viewer', 'lidl-baustandards-viewer-int']
    private static ROLE_COUNTRY_VIEWER_LIDL = [
        'lidl-baustandards-viewer-at',
        'lidl-baustandards-viewer-ba',
        'lidl-baustandards-viewer-be',
        'lidl-baustandards-viewer-bg',
        'lidl-baustandards-viewer-ch',
        'lidl-baustandards-viewer-cy',
        'lidl-baustandards-viewer-cz',
        'lidl-baustandards-viewer-de',
        'lidl-baustandards-viewer-dk',
        'lidl-baustandards-viewer-ee',
        'lidl-baustandards-viewer-es',
        'lidl-baustandards-viewer-fi',
        'lidl-baustandards-viewer-fr',
        'lidl-baustandards-viewer-gb',
        'lidl-baustandards-viewer-gr',
        'lidl-baustandards-viewer-hr',
        'lidl-baustandards-viewer-hu',
        'lidl-baustandards-viewer-ie',
        'lidl-baustandards-viewer-it',
        'lidl-baustandards-viewer-lt',
        'lidl-baustandards-viewer-lu',
        'lidl-baustandards-viewer-lv',
        'lidl-baustandards-viewer-me',
        'lidl-baustandards-viewer-mk',
        'lidl-baustandards-viewer-mt',
        'lidl-baustandards-viewer-nie',
        'lidl-baustandards-viewer-nl',
        'lidl-baustandards-viewer-pl',
        'lidl-baustandards-viewer-pt',
        'lidl-baustandards-viewer-ro',
        'lidl-baustandards-viewer-rs',
        'lidl-baustandards-viewer-se'
    ]

    public static SUPERADMIN = Roles.ROLE_SUPERADMIN
    public static ADMIN = [].concat(Roles.SUPERADMIN, Roles.ROLE_ADMIN)
    public static PUBLISHER = [].concat(Roles.ADMIN, Roles.ROLE_PUBLISHER)
    public static EDITOR = [].concat(Roles.PUBLISHER, Roles.ROLE_EDITOR)
    public static VIEWER = [].concat(Roles.EDITOR, Roles.ROLE_VIEWER, Roles.ROLE_COUNTRY_VIEWER_LIDL)
    public static MONITORING = [].concat(Roles.ROLE_SUPERADMIN, Roles.ROLE_MONITORING)
}
