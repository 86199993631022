import { Action } from '@ngrx/store'
import { Release } from '../../../entities/release.model'
import { FileContext } from '../../../entities/file-context.model'
import { File } from '../../../entities/file.model'

export enum ReleaseActionType {
    LOAD_RELEASE_CONTEXT_DATA = '[Release] Load release context',
    LOAD_RELEASE_DATA = '[Release] Load release data',
    SET_CURRENT_RELEASE = '[Release] Set data of current release',
    SET_RELEASES = '[Release] Set list of releases and extract current release',
    SET_REFERENCE = '[Release] Set reference release',
    TOGGLE_SIDEBAR = '[Release] Toggle sidebar',
    CLEAR_RELEASE_DATA = '[Release] Clear release data',
    SET_GALLERY_DATA = '[Release] Set gallery data',
    SET_PDF_DATA = '[Release] Set PDF data'
}

export type SetReleasesPayload = {
    currentReleaseId: number,
    releases: Release[],
    updateCurrentRelease: boolean
}

export type SetReferenceReleasePayload = Release

export class ReleaseAction implements Action {
    readonly type: ReleaseActionType

    constructor(public payload?: any) {}
}

export class LoadReleaseDataAction implements ReleaseAction {
    readonly type  = ReleaseActionType.LOAD_RELEASE_DATA

    constructor(public payload: any) {}
}

export class SetReleasesAction implements ReleaseAction {
    readonly type  = ReleaseActionType.SET_RELEASES

    constructor(public payload: SetReleasesPayload) {}
}

export class SetCurrentReleaseAction implements ReleaseAction {
    readonly type  = ReleaseActionType.SET_CURRENT_RELEASE

    constructor(public payload: Release) {}
}

export class SetGalleryDataAction implements ReleaseAction {
    readonly type = ReleaseActionType.SET_GALLERY_DATA

    constructor(public payload: FileContext[]) {}
}

export class SetPdfDataAction implements ReleaseAction {
    readonly type = ReleaseActionType.SET_PDF_DATA

    constructor(public payload: File[]) {}
}

export class ClearReleaseDataAction implements Action {
    readonly type  = ReleaseActionType.CLEAR_RELEASE_DATA
}

export class SetReferenceReleaseAction implements ReleaseAction {
    readonly type = ReleaseActionType.SET_REFERENCE

    constructor(public payload: SetReferenceReleasePayload) {}
}

export class ToggleSidebarAction implements ReleaseAction {
    readonly type = ReleaseActionType.TOGGLE_SIDEBAR

    constructor() {}
}
