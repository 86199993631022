import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'

import { UntilDestroy } from '@ngneat/until-destroy'
import { PageViewsService } from './'
import { ViewStatistics } from '../entities'
import { ActivatedRoute, Router } from '@angular/router'

@UntilDestroy()
@Component({
    selector: 'jhi-page-views',
    templateUrl: './page-views.component.html',
    styleUrls: [
        'page-views.scss'
    ]
})
export class PageViewsComponent implements OnInit, OnDestroy {

    private componentDestroyed: Subject<boolean> = new Subject<boolean>()

    perRelease: ViewStatistics[]

    unique: ViewStatistics[]

    uniquePerRelease: ViewStatistics[]

    total: ViewStatistics

    stats: ViewStatistics

    searchWord: string

    public showUnique = false
    public buttonName = 'Show Unique User Views'

    @Input()
    searchFilter: string[] = []

    constructor(
        public pageViewsService: PageViewsService,
        private readonly router: Router,
        protected route: ActivatedRoute) {
    }

    ngOnInit(): void {

        this.route.queryParams.subscribe((params) => {
            this.searchWord = params['searchWord'] || ''
            this.pageViewsService.query(this.searchWord)
                .subscribe((res) => {
                    this.total = res[0]
                    if (res.length > 1) {
                        this.perRelease = res.slice(1)
                    }
                })
            this.pageViewsService.queryUnique(this.searchWord)
                .subscribe((res) => {
                    this.unique = res
                })

            this.pageViewsService.queryUnique(this.searchWord)
                .subscribe((res) => {
                    this.stats = res[0]
                    if (res.length > 1) {
                        this.uniquePerRelease = res.slice(1)
                    }
                })
        })
    }

    ngOnDestroy() {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
    }

    toggleTables() {
        this.showUnique = !this.showUnique

        if (this.showUnique) {
            this.buttonName = 'Show Page View Statistics'
        } else {
            this.buttonName = 'Show Unique User Views'
        }
    }

    search() {
        this.perRelease = []
        this.router.navigate(['page-views'], {
            queryParams: {
                searchWord: this.searchWord
            }
        })
    }

}
