import { Component, OnInit } from '@angular/core'
import { combineLatest, Observable, of as observableOf } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import { BaseEntity } from '../../shared'
import { RevitService } from './revit.service'
import { RevitModel } from '../../entities'
import { catchError, switchMap } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'jhi-revit',
    templateUrl: './revit.component.html'
})
export class RevitComponent implements OnInit {

    item: BaseEntity
    type = ''
    buildingType: number
    searchWord: string
    country: string
    constructionType: string
    language: string

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public revitService: RevitService
    ) {
    }

    ngOnInit(): void {
        combineLatest([this.route.data, this.route.queryParams])
            .pipe(switchMap(([data, params]) => {
                this.type = data.type
                this.buildingType = params.buildingType
                this.searchWord = this.type === 'material' ? params.label : params.number
                this.country = params.country
                this.constructionType = params.constructionType
                this.language = params.language
                return this.findEntity(this.buildingType, this.searchWord, this.country, this.language, this.constructionType)
                    .pipe(catchError(() => {
                        return observableOf(new RevitModel(null, null, null, 'to_404')) // will redirect to 404
                    }))
            }), untilDestroyed(this))
            .subscribe((res) => {
                this.redirectTo(res)
            })
    }

    findEntity(buildingType: number, searchWord: string, country: string, language: string, constructionType: string): Observable<any> {
        if (this.type === 'material') {
            return this.revitService.findMaterial(buildingType, language, country, searchWord, constructionType)
        }
        if (this.type === 'room') {
            return this.revitService.findRoom(buildingType, language, country, searchWord, constructionType)
        }
        if (this.type === 'none') {
            return this.revitService.findRelease(buildingType, language, country, constructionType)
        }
    }

    redirectTo(revitModel: RevitModel): void {
        if (revitModel.redirectType === 'to_release') {
            const constructionType = revitModel.construction ? 'construction' : 'modernization'
            this.router.navigateByUrl(`${revitModel.projectName}/${this.language}/${constructionType}`)
            return
        }
        if (revitModel.redirectType === 'to_404') {
            this.router.navigateByUrl(`errors/not-found`, { skipLocationChange: true })
            return
        }
        if (revitModel.redirectType === 'to_entity') {
            setTimeout(() => {
                const constructionType = revitModel.construction ? 'construction' : 'modernization'
                this.router.navigateByUrl(`${revitModel.projectName}/${this.language}/${constructionType}/${this.type}/${revitModel.originalId}`)
            }, 1)
        }
    }
}
