import { Routes } from '@angular/router'
import { NotFoundComponent } from './not-found.component'
import { UserRouteAccessService } from '../shared/auth/user-route-access-service'

const notFoundPageTitle = 'notFound.title'

export const notFoundRoute: Routes = [{
        path: '**',
        component: NotFoundComponent,
        data: {
            authorities: [],
            pageTitle: notFoundPageTitle
        },
        canActivate: [UserRouteAccessService]
    },
    // This route is used as a fallback, because we are navigating to it in the OnInit hook
    {
        path: 'errors/not-found',
        component: NotFoundComponent,
        data: {
            authorities: [],
            pageTitle: notFoundPageTitle
        },
        canActivate: [UserRouteAccessService]
    }
]
