import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { RouterModule } from '@angular/router'

import { JhipsterSharedModule } from '../shared'
import { PageViewsComponent, pageViewRoutes } from './'
import { PageViewsService } from './'
@NgModule({
    imports: [
        JhipsterSharedModule,
        RouterModule.forChild(pageViewRoutes)
    ],
    declarations: [
        PageViewsComponent
    ],
    entryComponents: [
    ],
    providers: [
        PageViewsService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageViewsModule {}
