import { Routes } from '@angular/router'

import { UserRouteAccessService } from '../../shared'
import { AttachmentCategoryDetailComponent } from './attachment-category-detail.component'
import { Roles } from '../../shared/auth/roles'

export const attachmentCategoryRoutes: Routes = [
    {
        path: 'attachment-category/:id',
        component: AttachmentCategoryDetailComponent,
        data: {
            authorities: Roles.VIEWER
        },
        canActivate: [UserRouteAccessService]
    }
]
