import { LoadingState } from '../state'
import { LoadingActionType, LoadingAction } from '../actions/loading.action'

const initialState: LoadingState = {
    content: false,
    pdf: false
}

export function loadingReducer(state = initialState, action: LoadingAction) {
    switch (action.type) {
        case LoadingActionType.START_LOADING:
            if (state.content) {
                return state
            }
            return {
                ...state,
                content: true
            }
        case LoadingActionType.STOP_LOADING:
            if (!state.content) {
                return state
            }
            return {
                ...state,
                content: false
            }
        default:
            return state
    }
}
