import { Component, OnDestroy, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core'
import { combineLatest, Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'

import { Craft } from '../../entities/craft.model'
import { CraftService } from './craft.service'
import { Store, select } from '@ngrx/store'
import { BookState } from '../redux/book.state'
import { ToggleSidebarAction, SetGalleryDataAction, SetPdfDataAction } from '../redux/actions/release.action'
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { SelectTableOfContentsAction } from '../redux/actions/book-navigation.action'
import { StateStorageService, TitleService } from '../../shared'
import { PdfCollectorService } from '../common/pdf-collector.service'
import { selectQueryParams, shouldShowSidebar } from '../redux/state'
import { contentContainerRequest$ } from '../common/content-container-request'
import { StopLoadingAction, StartLoadingAction } from '../redux/actions/loading.action'
import { Params } from '@angular/router'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { FileContext } from '../../entities'
import { concat } from 'lodash'
import { ProfileEnvironmentEnum } from '../../layouts/profiles/profile.service'

@UntilDestroy()
@Component({
    selector: 'craft-detail',
    templateUrl: './craft-detail.component.html',
    styleUrls: ['./craft-detail.component.scss']
})
export class CraftDetailComponent implements OnInit, OnDestroy, AfterViewInit {

    public craft: Craft

    public selectedRelease: any

    public hasLoaded = false

    public hasImages = false

    public hasLinkedAttachmentCategories = false

    public hasFiles = false

    public activeIds: string[] = []

    public showSidebar$: Observable<boolean>

    public queryParams$: Observable<Params>

    public isLidl: boolean = this.stateStorageService.getProfileEnvironment() === ProfileEnvironmentEnum.Lidl

    @ViewChildren(NgbAccordion) accordions !: QueryList<NgbAccordion>

    constructor(private readonly craftService: CraftService,
        private readonly titleService: TitleService,
        private readonly store: Store<BookState>,
        private readonly pdfCollectorService: PdfCollectorService,
        private readonly sanitizer: DomSanitizer,
        private readonly stateStorageService: StateStorageService
    ) {
    }

    ngOnInit(): void {

        this.showSidebar$ = this.store.pipe(select(shouldShowSidebar))

        this.store.dispatch(new SelectTableOfContentsAction())

        this.queryParams$ = this.store.pipe(select(selectQueryParams))

        contentContainerRequest$(this.store)
            .pipe(
                switchMap(([params, currentRelease, selectedRelease]) => {
                    if (params.id && currentRelease.id) {

                        const craftId = parseInt(params.id, 10)
                        const currentReleaseId = currentRelease.id

                        // We only need to call the backend if the craft has actually changed or a release is selected
                        if (!this.craft || craftId !== this.craft.originalId || selectedRelease) {

                            if (selectedRelease && selectedRelease.id !== currentReleaseId) {
                                return this.loadCraft(currentReleaseId, craftId, selectedRelease.id)
                            } else {
                                return this.loadCraft(currentReleaseId, craftId)
                            }

                        } else {
                            this.titleService.setTitle(this.craft.heading)
                        }
                    }
                }),
                untilDestroyed(this)

            ).subscribe((craft: Craft) => {
                this.handleCraft(craft)
            })
    }

    ngAfterViewInit() {

        combineLatest([this.showSidebar$, this.accordions.changes]).pipe(untilDestroyed(this)).subscribe(([showSidebar, accordions]) => {

            setTimeout(() => {
                accordions.forEach((accordion) => {

                    if (!showSidebar) {
                        accordion.closeOtherPanels = false
                        accordion.expandAll()
                    } else {
                        accordion.closeOtherPanels = true
                    }
                })
            }, 0)

        })

    }

    ngOnDestroy(): void {
        this.store.dispatch(new StopLoadingAction())
    }

    private loadCraft(releaseId: number, originalId: number, selectedReleaseId?: number) {
        this.store.dispatch(new StopLoadingAction())
        this.store.dispatch(new StartLoadingAction())

        return this.craftService
            .find(releaseId, originalId, selectedReleaseId)

    }

    private handleCraft(craft: Craft) {
        this.craft = craft
        this.titleService.setTitle(craft.heading)
        this.hasLoaded = true
        this.store.dispatch(new SetGalleryDataAction(this.craft.images))

        let pdfFiles = this.pdfCollectorService.collectPdfs(this.craft)

        if (craft.hasProductList) {
            pdfFiles = concat(pdfFiles, craft.productLists.map((fc) => fc.file))
        }

        this.hasImages = this.craft.images.length > 0
        this.hasLinkedAttachmentCategories = this.craft.linkedAttachmentCategories.length > 0
        this.hasFiles = this.craft.pdfs.length > 0 || this.craft.files.length > 0

        this.store.dispatch(new SetPdfDataAction(pdfFiles))

        this.store.dispatch(new StopLoadingAction())

        this.setInitiallyOpenedPanel()
    }

    toggleSidebar() {
        this.store.dispatch(new ToggleSidebarAction())
    }

    public sanitizeImage(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url)
    }

    track(fileContext: FileContext) {
        return fileContext.id
    }

    setInitiallyOpenedPanel(): void {
        this.activeIds = []

        if (this.hasImages) {
            this.activeIds = ['ngb-panel-images']
        } else if (this.hasLinkedAttachmentCategories) {
            this.activeIds = ['ngb-panel-attachment-categories' + 0]
        } else if (this.hasFiles) {
            this.activeIds = ['ngb-panel-files']
        } else if (this.craft.hasProductList && this.craft.productLists.length > 0) {
            this.activeIds = ['ngb-panel-product-lists']
        }
    }
}
