import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'
import { AttachmentCategory } from '../../entities/attachment-category.model'

@Injectable()
export class AttachmentCategoryService {

    constructor(private readonly httpClient: HttpClient) {
    }

    find(releaseId: number, id: number): Observable<AttachmentCategory> {
        return this.httpClient.get<AttachmentCategory>(`${SERVER_API_URL}/api/releases/${releaseId}/attachment-categories/${id}`)
    }
}
