import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core'
import { CopyToClipboardHelper } from './copy-to-clipboard.helper'

@Component({
    selector: 'copy-to-clipboard',
    templateUrl: './copy-to-clipboard.component.html',
    styleUrls: [
        'copy-to-clipboard.scss'
    ]
})
export class CopyToClipboardComponent implements OnInit, OnDestroy {

    @Input()
    text: string

    constructor(private readonly copyToClipboardHelper: CopyToClipboardHelper) { }

    ngOnInit() {
    }

    @HostListener('click')
    onShare() {
        const navigator = window.navigator as any
        if (navigator.share) {
            navigator.share({
                title: document.title,
                text: document.title,
                url: window.location.href
            })
        } else {
            this.copyToClipboardHelper.copyToClipboard(this.text)
        }

    }

    ngOnDestroy() {
    }
}
