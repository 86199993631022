import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'
import { SearchResult } from './search-result.model'
import { ResponseWrapper } from '../../shared'
import { map } from 'rxjs/operators'
import { MaterialCategoryDropdown } from './material-category-dropdown.interface'

@Injectable()
export class SearchService {

    constructor(private readonly httpClient: HttpClient) {
    }

    query(releaseId: number, query: string, materialCategoryOriginalId: number, page: number, pageSize: number): Observable<ResponseWrapper> {
        let params: HttpParams = new HttpParams()
            .set('q', query)
            .set('page', (page - 1).toString())
            .set('size', pageSize.toString())
        if (materialCategoryOriginalId) {
            params = params.append('materialCategoryOriginalId', materialCategoryOriginalId)
        }
        return this.httpClient.get(`${SERVER_API_URL}/api/releases/${releaseId}/search`, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res))
        )
    }

    getRelatedResults(releaseId: number, query: string, materialCategoryOriginalId: number): Observable<ResponseWrapper> {
        let  params: HttpParams = new HttpParams().set('q', query)
        if (materialCategoryOriginalId) {
            params = params.append('materialCategoryOriginalId', materialCategoryOriginalId)
        }
        return this.httpClient.get(`${SERVER_API_URL}/api/releases/${releaseId}/related-search`, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res))
        )
    }

    getMaterialCategories(): Observable<MaterialCategoryDropdown[]> {
        return this.httpClient.get<MaterialCategoryDropdown[]>(`${SERVER_API_URL}/api/material-categories/dropdown`)
    }

    private convertResponse(res) {
        const results = []
        for (let i = 0; i < res.body.length; i++) {
            results.push(this.convertItemFromServer(res.body[i]))
        }

        return new ResponseWrapper(res.headers, results, res.status)
    }

    private convertItemFromServer(json: any): SearchResult {
        return Object.assign(new SearchResult(), json)

    }
}
