
import { throwError as observableThrowError, Observable } from 'rxjs'

import { catchError, tap } from 'rxjs/operators'
import { JhiAlertService } from 'ng-jhipster'
import { Injectable, Injector } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {

    private alertService: JhiAlertService

    constructor(private readonly injector: Injector) {
        setTimeout(() => this.alertService = this.injector.get(JhiAlertService))
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((res) => {
                if (!(res instanceof HttpResponse)) {
                    return res
                }

                const headers = []
                res.headers.keys().forEach((key) => {
                    if (key.toLowerCase().endsWith('app-alert') || key.toLowerCase().endsWith('app-params')) {
                        headers.push(key)
                    }
                })
                if (headers.length > 1) {
                    headers.sort()
                    const alertKey = res.headers.get(headers[0])
                    if (typeof alertKey === 'string') {
                        if (this.alertService && !req.headers.has('X-Ignore-Alerts')) {
                            const alertParam = headers.length >= 2 ? res.headers.get(headers[1]) : null
                            this.alertService.success(alertKey, {param: alertParam}, null)
                        }
                    }
                }

                return res
            }),
            catchError((error) => {
                return observableThrowError(error)
            }), )
    }
}
