import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RevitComponent } from './revit.component'
import { RouterModule } from '@angular/router'
import { revitRoutes } from './revit.route'
import { JhipsterSharedModule } from '../../shared'

const ENTITY_STATES = [
    ...revitRoutes
]

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(ENTITY_STATES),
        JhipsterSharedModule
    ],
    declarations: [RevitComponent],
    exports: [RevitComponent]
})
export class RevitModule {

}
