import { Component, Input, OnInit } from '@angular/core'
import { Attachment, FileContext } from '../../../entities'
import { SafeUrl, DomSanitizer } from '@angular/platform-browser'
import { Store, select } from '@ngrx/store'
import { BookState } from '../../redux/book.state'
import { Observable } from 'rxjs'
import { Params } from '@angular/router'
import { selectQueryParams } from '../../redux/state'

@Component({
    selector: 'attachment-list',
    templateUrl: 'attachment-list.component.html',
    styleUrls: ['attachment-list.component.scss']
})
export class AttachmentListComponent implements OnInit {

    @Input()
    attachments: Attachment[]

    queryParams$: Observable<Params>

    constructor(private readonly sanitizer: DomSanitizer, private readonly store: Store<BookState>) {}

    public sanitizeImage(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url)
    }

    ngOnInit() {
        this.queryParams$ = this.store.pipe(select(selectQueryParams))
    }

    track(fileContext: FileContext) {
        return fileContext.id
    }
}
