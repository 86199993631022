import { createFeatureSelector, createSelector } from '@ngrx/store'

export interface LoadingState {
    content: boolean
    pdf: boolean
}

export const getLoadingState = createFeatureSelector<LoadingState>('loading')

export const isLoading = createSelector(
    getLoadingState,
    (state: LoadingState) => state.content
)

export const isLoadingPdf = createSelector(
    getLoadingState,
    (state: LoadingState) => state.pdf
)
