import { Injectable } from '@angular/core'
import { Effect, ofType, Actions } from '@ngrx/effects'
import { ReleaseActionType, LoadReleaseDataAction, SetReleasesAction } from '../actions/release.action'
import { map, catchError, switchMap } from 'rxjs/operators'
import { EMPTY } from 'rxjs'
import { ReleaseService } from '../../release.service'
@Injectable({
    providedIn: 'root'
})
export class ReleaseEffects {

    @Effect()
    loadReleases$ = this.actions$
        .pipe(
            ofType(ReleaseActionType.LOAD_RELEASE_DATA),
            switchMap((action: LoadReleaseDataAction) => {
                return this.releaseService
                    .findAllReleasesByReleaseContextIdOrReleaseId(action.payload.releaseContextId, action.payload.releaseId)
                    .pipe(
                        map((releases) => [action.payload.releaseId, action.payload.updateCurrentRelease, releases]),
                        map(([currentReleaseId, updateCurrentRelease, releases]) => new SetReleasesAction({currentReleaseId, releases, updateCurrentRelease})),
                        catchError(() => EMPTY))
            })
        )

    constructor(
        private actions$: Actions,
        private releaseService: ReleaseService
    ) { }

}
