import { Component, OnInit } from '@angular/core'
import { PageViewsReleasesService } from './'
import { ReleaseViewStats } from '../entities'
import { ActivatedRoute } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'page-views-releases',
    templateUrl: './page-views-releases.component.html',
    styleUrls: ['./page-views-releases.scss']
})
export class PageViewsReleasesComponent implements OnInit {

    releaseStats: ReleaseViewStats

    releaseId: number

    releaseName: string

    constructor(
        public pageViewsReleasesService: PageViewsReleasesService, public activatedRoute: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe((params) => {
            const releaseId = params['id']
            this.releaseId = releaseId
            this.pageViewsReleasesService.queryDetails(releaseId).pipe(untilDestroyed(this)).subscribe((response) => {
                this.releaseStats = response
                this.releaseName = this.releaseStats.releaseName
            })
        })
    }

}
