import { HostListener, Directive, ElementRef, Output, EventEmitter } from '@angular/core'

@Directive({
    selector: '[fileDrop]'
})
export class FileDropDirective {

    @Output()
    droppedFiles = new EventEmitter<File[]>()

    constructor(private elementRef: ElementRef) { }

    //
    // Called when the element has content dragged over
    //
    @HostListener('dragover', ['$event'])
    public onDragOver(event: Event): void {
        if (!this.elementRef.nativeElement.classList.contains('active')) {
            this.elementRef.nativeElement.classList.add('active')
        }

        this.disableDefault(event)

    }

    //
    // Called when the element has dragged content leave
    //
    @HostListener('dragleave', ['$event'])
    public onDragLeave(event: Event): void {
        this.elementRef.nativeElement.classList.remove('active')

        this.disableDefault(event)
    }

    //
    // Called when the element has content dropped
    //
    @HostListener('drop', ['$event'])
    public onDrop(event: Event): void {
        this.elementRef.nativeElement.classList.remove('active')

        const dataTransfer = (<DragEvent>event).dataTransfer

        const files: File[] = []

        if (dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (let i = 0; i < dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (dataTransfer.items[i].kind === 'file') {
                    const file = dataTransfer.items[i].getAsFile()
                    files.push(file)
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for (let i = 0; i < dataTransfer.files.length; i++) {
                const file = dataTransfer.files[i]
                files.push(file)
            }
        }

        this.processDroppedFiles(files)

        this.disableDefault(event)
    }

    private disableDefault(event: Event) {
        event.stopPropagation()
        event.preventDefault()
    }

    private processDroppedFiles(files: File[]) {
        this.droppedFiles.emit(files)
    }

}
