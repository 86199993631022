import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { CopyToClipboardHelper } from '../copy-to-clipboard/copy-to-clipboard.helper'

@Component({
    selector: 'share-link',
    templateUrl: './share-link.component.html',
    styleUrls: [
        'share-link.scss'
    ]
})
export class ShareLinkComponent implements OnInit, OnDestroy {

    constructor(private readonly copyToClipboardHelper: CopyToClipboardHelper) { }

    ngOnInit() {
    }

    @HostListener('click')
    onShare() {
        const navigator = window.navigator as any
        if (navigator.share) {
            navigator.share({
                title: document.title,
                text: document.title,
                url: window.location.href
            })
        } else {
            this.copyToClipboardHelper.copyToClipboard(window.location.href)
        }
    }

    ngOnDestroy() {
    }
}
