import { NgModule, LOCALE_ID } from '@angular/core'
import { Title } from '@angular/platform-browser'

import { JhipsterSharedLibsModule } from './shared-libs.module'
import { FindLanguageFromKeyPipe } from './language/find-language-from-key.pipe'
import { JhiAlertComponent } from './alert/alert.component'
import { JhiAlertErrorComponent } from './alert/alert-error.component'
import { IconComponent } from './icon/icon.component'
import { JhiLanguageHelper } from './language/language.helper'
import { CopyToClipboardHelper } from './copy-to-clipboard/copy-to-clipboard.helper'

@NgModule({
    imports: [
        JhipsterSharedLibsModule,
    ],
    declarations: [
        FindLanguageFromKeyPipe,
        JhiAlertComponent,
        JhiAlertErrorComponent,
        IconComponent,
    ],
    providers: [
        JhiLanguageHelper,
        CopyToClipboardHelper,
        Title,
        {
            provide: LOCALE_ID,
            useValue: 'en'
        },
    ],
    exports: [
        JhipsterSharedLibsModule,
        FindLanguageFromKeyPipe,
        JhiAlertComponent,
        JhiAlertErrorComponent,
        IconComponent,
    ]
})
export class JhipsterSharedCommonModule {}
