import { Injectable, Renderer2, RendererFactory2 } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'

import { LANGUAGES } from './language.constants'

@Injectable()
export class JhiLanguageHelper {
    renderer: Renderer2 = null

    constructor(
        rootRenderer: RendererFactory2,
        private readonly translateService: TranslateService,
        private readonly titleService: Title,
        private readonly router: Router
    ) {
        this.renderer = rootRenderer.createRenderer(document.querySelector('html'), null)
        this.init()
    }

    getAll(): Promise<any> {
        return Promise.resolve(LANGUAGES)
    }

    /**
     * Update the window title using params in the following
     * order:
     * 1. titleKey parameter
     * 2. $state.$current.data.pageTitle (current state page title)
     * 3. 'global.title'
     */
    updateTitle(titleKey?: string) {
        if (!titleKey) {
            // This will return 'kstandards' if no title key is found.
            titleKey = this.getPageTitle(this.router.routerState.snapshot.root)
        }

        // Do not set the title if the translation is missing.
        // That might happen when dynamically setting the title in the book components.
        if (titleKey === 'kstandards') {
            return
        }

        this.translateService.get(titleKey).subscribe((title) => {
            this.titleService.setTitle(title)
        })
    }

    private init() {
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.renderer.setAttribute(document.querySelector('html'), 'lang', this.translateService.currentLang)
            this.updateTitle()
        })
    }

    private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
        let title: string = (routeSnapshot.data && routeSnapshot.data['pageTitle']) ? routeSnapshot.data['pageTitle'] : 'kstandards'
        if (routeSnapshot.firstChild) {
            title = this.getPageTitle(routeSnapshot.firstChild) || title
        }

        return title
    }
}
