import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener, EventEmitter, Output } from '@angular/core'
import { filter, map } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Release } from '../../entities/release.model'
import { BookState } from '../redux/book.state'
import { Store, select } from '@ngrx/store'
import { SetReferenceReleaseAction } from '../redux/actions/release.action'
import { getCurrentRelease, getReferenceRelease, getAllReleasesForContext } from '../redux/state/release.state'

@UntilDestroy()
@Component({
    selector: 'reference-select',
    templateUrl: 'reference-select.component.html',
    styleUrls: ['reference-select.component.scss']
})
export class ReferenceSelectComponent implements OnInit, OnDestroy {

    public releases: Release[]
    public selectedReference: Release
    public currentRelease: Release

    /*
     * Dropdown data
     */
    @ViewChild('dropdownRelease', {read: ElementRef, static: true}) dropdownRelease: ElementRef

    showReleaseDropdownMenu = false

    @Output()
    saveChanges = new EventEmitter<never>()

    constructor(private store: Store<BookState>) {
    }

    ngOnInit() {

        this.store.pipe(
            select(getReferenceRelease), filter((reference) => reference.loaded), map((reference) => reference.release),
            untilDestroyed(this)
        ).subscribe((selectedReference) => {
            this.selectedReference = selectedReference
        })

        this.store.pipe(
            select(getCurrentRelease),
            untilDestroyed(this)
        ).subscribe((currentRelease) => {
            this.currentRelease = currentRelease
        })

        this.store.pipe(
            select(getAllReleasesForContext),
            filter((partialReleaseState) => partialReleaseState.loaded),
            map((partialReleaseState) => partialReleaseState.data)
        )
        .pipe(
            untilDestroyed(this)
        ).subscribe((releases: Release[]) => {
            this.releases = releases.filter((item) => {
                if (item.published) {
                    return true
                }
                return this.currentRelease.defaultReferenceRelease?.id === item.id
            })
        })

    }

    /**
     *
     * Show/hide the release drop down.
     */
    toggleReleaseDropdownMenu() {
        this.showReleaseDropdownMenu = !this.showReleaseDropdownMenu
    }

    /**
     *
     * Setting release and closing the dropdown.
     * @param {release} release
     */
    setRelease(release: Release) {
        this.toggleReleaseDropdownMenu()
        this.selectedReference = release
    }

    emitRelease() {
        this.saveChanges.emit()
        this.store.dispatch(new SetReferenceReleaseAction(this.selectedReference))
    }

    /**
     *
     * When you click outside of the element hide the dropdown.
     * @param event - outside click.
     */
    @HostListener('document:click', ['$event'])
    onClickOutside(event) {

        if (!this.dropdownRelease.nativeElement.contains(event.target)) {
            if (this.showReleaseDropdownMenu) {
                this.toggleReleaseDropdownMenu()
            }
        }
    }

    ngOnDestroy() {

    }

}
