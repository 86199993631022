import { Routes } from '@angular/router'

import { RevitComponent } from './revit.component'
import { Roles, UserRouteAccessService } from '../../shared'

export const revitRoutes: Routes = [
    {
        path: 'revit/materials',
        component: RevitComponent,
        data: {
            authorities: Roles.VIEWER,
            type: 'material'
        },
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'revit/rooms',
        component: RevitComponent,
        data: {
            authorities: Roles.VIEWER,
            type: 'room'
        },
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'revit/none',
        component: RevitComponent,
        data: {
            authorities: Roles.VIEWER,
            type: 'none'
        },
        canActivate: [UserRouteAccessService],
    }
]
