import { Action } from '@ngrx/store'

export enum LoadingActionType {
    START_LOADING = '[Loading] Start loading content',
    STOP_LOADING = '[Loading] Stop loading content',
    START_LOADING_PDF = '[Loading] Start loading PDF',
    STOP_LOADING_PDF = '[Loading] Stop loading PDF',
}

export class LoadingAction implements Action {
    readonly type: LoadingActionType

    constructor(public payload?: any) { }
}

export class StartLoadingAction implements LoadingAction {
    readonly type = LoadingActionType.START_LOADING
}

export class StopLoadingAction implements LoadingAction {
    readonly type = LoadingActionType.STOP_LOADING
}

export class StartLoadingPdfAction implements LoadingAction {
    readonly type = LoadingActionType.START_LOADING_PDF
}

export class StopLoadingPdfAction implements LoadingAction {
    readonly type = LoadingActionType.STOP_LOADING_PDF
}
