import { Component } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import { BookNavigationMode } from '../book-navigation-mode'
import { SelectRoombookAction, SelectTableOfContentsAction } from '../../redux/actions/book-navigation.action'
import { BookNavigationState, hasRoombookData, hasTableOfContentsData, selectBookNavigationMode } from '../../redux/state/book-navigation.state'
import { distinctUntilChanged } from 'rxjs/operators'

@Component({
    selector: 'tab-menu',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {

    public bookNavigationMode: Observable<BookNavigationMode>

    public hasRoombookData$: Observable<boolean>
    public hasTableOfContentsData$: Observable<boolean>

    public bookNavigationEnum = BookNavigationMode

    constructor(private readonly store: Store<BookNavigationState>) {
        this.bookNavigationMode = this.store.pipe(select(selectBookNavigationMode), distinctUntilChanged())
        this.hasRoombookData$ = this.store.select(hasRoombookData)
        this.hasTableOfContentsData$ = this.store.select(hasTableOfContentsData)

    }

    public switchMode(bookNavigationMode: BookNavigationMode) {
        switch (bookNavigationMode) {
            case BookNavigationMode.ROOMBOOK:
                this.store.dispatch(new SelectRoombookAction())
                break
            case BookNavigationMode.TABLE_OF_CONTENTS:
                this.store.dispatch(new SelectTableOfContentsAction())
                break
        }
    }
}
