import { Routes } from '@angular/router'

import { PageViewsComponent } from '.'
import { UserRouteAccessService } from '../shared/auth/user-route-access-service'

export const pageViewRoutes: Routes = [
    {
        path: 'page-views',
        component: PageViewsComponent,
        data: {
            authorities: [],
            pageTitle: 'kstandards.home.pageTitle'
        },
        canActivate: [UserRouteAccessService]
    }
]
