import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { JhipsterSharedModule } from '../../shared'
import { AttachmentListComponent } from './attachment-list/attachment-list.component'
import { FileListComponent } from './file-list/file-list.component'
import { PdfCollectorService } from './pdf-collector.service'
import { ProductListComponent } from './product-list/product-list.component'
import { SimpleFileListComponent } from './simple-file-list/simple-file-list.component'
import { MaterialCategoryListComponent } from './material-category-list/material-category-list.component'
import { MaterialCategoryListItemComponent } from './material-category-list-item/material-category-list-item.component'

@NgModule({
    imports: [JhipsterSharedModule, CommonModule, RouterModule],
    declarations: [AttachmentListComponent, FileListComponent, ProductListComponent, SimpleFileListComponent, MaterialCategoryListComponent, MaterialCategoryListItemComponent],
    exports: [AttachmentListComponent, FileListComponent, ProductListComponent, SimpleFileListComponent, MaterialCategoryListComponent, MaterialCategoryListItemComponent],
    providers: [PdfCollectorService]
})
export class CommonBookModule {

}
