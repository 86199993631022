import { Pipe, PipeTransform } from '@angular/core'

const MATERIAL_CATEGORY_ICON_REGEX = /<\[?MATERIAL\\*_CATEGORY\\*_ICON\+?\}?_\{?\+?(\d+)\]?>/g

const DIFF_START = /(\{\+)*/g
const DIFF_END = /(\+\})*/g

@Pipe({
    name: 'deleteCustomTags'
})
export class DeleteCustomTagsPipe implements PipeTransform {

    transform(content: string): string {
        if (!content) {
            return ''
        }

        if (this.checkRegex(DIFF_START, content)) {
            content = content.replace(DIFF_START, '')
        }

        if (this.checkRegex(DIFF_END, content)) {
            content = content.replace(DIFF_END, '')
        }

        if (this.checkRegex(MATERIAL_CATEGORY_ICON_REGEX, content)) {
            content = content.replace(MATERIAL_CATEGORY_ICON_REGEX, '')
        }
        return content.trim()
    }

    private checkRegex(regex: RegExp, content: string): boolean {
        regex.lastIndex = 0
        return regex.test(content)
    }

}
