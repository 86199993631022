import { Component, OnInit } from '@angular/core'
import { combineLatest, Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { AttachmentCategoryService } from './attachment-category.service'
import { AttachmentCategory } from '../../entities/attachment-category.model'
import { flatMap } from 'lodash'
import { TitleService } from '../../shared'
import { select, Store } from '@ngrx/store'
import { BookState } from '../redux/book.state'
import { getCurrentRelease } from '../redux/state/release.state'
import { selectRouteParams, selectQueryParams } from '../redux/state'
import { SetPdfDataAction } from '../redux/actions/release.action'
import { Params } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'attachment-category',
    templateUrl: './attachment-category-detail.component.html',
    styleUrls: ['./attachment-category-detail.component.scss']
})
export class AttachmentCategoryDetailComponent implements OnInit {

    public attachmentCategory: AttachmentCategory

    public queryParams$: Observable<Params>

    currentReleaseId
    attachmentCategoryId

    constructor(private readonly attachmentCategoryService: AttachmentCategoryService,
        private readonly titleService: TitleService,
        private readonly store: Store<BookState>) {
    }

    ngOnInit(): void {

        const currentRelease$ = this.store.pipe(select(getCurrentRelease), filter((currentRelease) => !!currentRelease))
        const params$ = this.store.pipe(select(selectRouteParams), filter((params) => !!params))

        this.queryParams$ = this.store.pipe(select(selectQueryParams))

        combineLatest([params$, currentRelease$])
            .pipe(untilDestroyed(this)).subscribe(([params, currentRelease]) => {
                if (params.id && currentRelease.id) {

                    this.attachmentCategoryId = parseInt(params.id, 10)
                    this.currentReleaseId = currentRelease.id

                    // We only need to call the backend if the attachment category has actually changed or a release is selected
                    if (!this.attachmentCategory || this.attachmentCategoryId !== this.attachmentCategory.originalId) {
                        this.loadAttachmentCategory()
                    } else {
                        this.titleService.setTitle(this.attachmentCategory.heading)
                    }
                }
            })
    }

    loadAttachmentCategory() {
        // We set the attachment categories to null to trigger the loading spinner
        this.attachmentCategory = null

        this.attachmentCategoryService
            .find(this.currentReleaseId, this.attachmentCategoryId)
            .pipe(
                untilDestroyed(this)
            )
            .subscribe((attachmentCategory) => {
                this.attachmentCategory = attachmentCategory
                // On this page, we sort by filename since we just display all the files
                const files = flatMap(attachmentCategory.attachments, (attachment) => attachment.fileContexts.map((fileContext) => fileContext.file))
                this.store.dispatch(new SetPdfDataAction(files))

                this.titleService.setTitle(attachmentCategory.heading)
            })
    }
}
