import { Action } from '@ngrx/store'

export enum LanguageActionType {
    CHANGE = '[Language] Change language'
}

export abstract class LanguageAction implements Action {
    readonly type: LanguageActionType

    constructor(public payload: any) {}
}

export class ChangeLanguageAction implements LanguageAction {
    readonly type: LanguageActionType = LanguageActionType.CHANGE

    constructor(public payload: SetLanguagePayload) {}
}

export type SetLanguagePayload = {
    newLanguage: string
}
