import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Language } from '../entities/language.model'

@Injectable()
export class LanguageService {

    constructor(private readonly http: HttpClient) {
    }

    query(): Observable<Language[]> {
        return this.http.get<Language[]>(`/api/languages`)
    }
}
