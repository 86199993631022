import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../../app.constants'
import { RoomGroup, Room } from '../../../entities'
import { map } from 'rxjs/operators'

@Injectable()
export class RoomBookService {

    constructor(private readonly httpClient: HttpClient) {
    }

    getRoombook(releaseId: number): Observable<RoomGroup[]> {
        return this.httpClient.get<RoomGroup[]>(`${SERVER_API_URL}/api/releases/${releaseId}/room-book`, { observe: 'body' })
            .pipe(map((roomGroups) => {
                return roomGroups.map((roomGroup) => this.convertServerResponse(roomGroup))
            }))
    }

    private convertServerResponse(roomGroup) {
        const converted = Object.assign(new RoomGroup(), roomGroup)

        if (roomGroup.rooms) {
            converted.rooms = roomGroup.rooms.map((room) => Object.assign(new Room(), room))
        }

        return converted
    }
}
