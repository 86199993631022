import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { Params } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { FileContext } from '../../../entities'
import { ContentContainer } from '../../../entities/content-container.model'
import { BookState } from '../../redux/book.state'
import { selectQueryParams } from '../../redux/state'

@Component({
    selector: 'file-list',
    templateUrl: 'file-list.component.html',
    styleUrls: ['file-list.component.scss']
})
export class FileListComponent implements OnInit {

    @Input()
    contentContainer: ContentContainer

    queryParams$: Observable<Params>

    constructor(private readonly sanitizer: DomSanitizer, private readonly store: Store<BookState>) { }

    public sanitizeImage(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url)
    }

    ngOnInit() {
        this.queryParams$ = this.store.pipe(select(selectQueryParams))
    }

    track(fileContext: FileContext) {
        return fileContext.id
    }
}
