import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'dropdown-material-category-item',
  templateUrl: './dropdown-material-category-item.component.html',
  styleUrls: ['./dropdown-material-category-item.component.scss']
})
export class DropdownMaterialCategoryItemComponent implements OnInit {

    @Input()
    item

    @Output()
    itemSelected: EventEmitter<any> = new EventEmitter<any>()

    constructor() { }

    ngOnInit(): void {
    }

}
