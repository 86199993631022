import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { RouterModule } from '@angular/router'

import { JhipsterSharedModule } from '../shared'

import { legalNoticeRoutes, LegalNoticeComponent } from '.'

@NgModule({
    imports: [
        JhipsterSharedModule,
        RouterModule.forChild(legalNoticeRoutes)
    ],
    declarations: [
        LegalNoticeComponent,
    ],
    entryComponents: [
    ],
    providers: [
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LegalNoticeModule {}
