import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router'

import { JhiLanguageHelper } from '../../shared'
import { LanguageChoiceService } from '../../shared/language/language-choice.service'
import { OAuth2Service } from '../../shared/auth/oauth2.service'
import { LanguageState } from '../../book/redux/state/language.state'
import { Store } from '@ngrx/store'
import { ChangeLanguageAction } from '../../book/redux/actions/language.action'

@Component({
    selector: 'jhi-main',
    templateUrl: './main.component.html'
})
export class JhiMainComponent implements OnInit {

    constructor(
        private readonly jhiLanguageHelper: JhiLanguageHelper,
        private readonly languageStore: Store<LanguageState>,
        private readonly languageChoiceService: LanguageChoiceService,
        private readonly oauth2Service: OAuth2Service,
        private readonly router: Router
    ) {

    }

    private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
        let title: string = (routeSnapshot.data && routeSnapshot.data['pageTitle']) ? routeSnapshot.data['pageTitle'] : null

        if (!title) {
            return null
        }

        if (routeSnapshot.firstChild) {
            title = this.getPageTitle(routeSnapshot.firstChild) || title
        }
        return title
    }

    ngOnInit() {

        this.oauth2Service.initOAuth()
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const title = this.getPageTitle(this.router.routerState.snapshot.root)

                if (title !== null) {
                    this.jhiLanguageHelper.updateTitle(title)
                }
            }
        })

        const preferredLanguage = this.languageChoiceService.getPreferredLanguage()
        this.languageStore.dispatch(new ChangeLanguageAction({newLanguage: preferredLanguage}))
    }
}
