import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core'
import { OAuth2Service, TitleService } from '../shared'
import { ReleaseService } from '../book/release.service'
import { ReleaseContext } from '../entities/release-context.model'
import { Language } from '../entities/language.model'
import { Country } from '../entities/country.model'
import { LanguageService } from './language.service'
import { CountryService } from './country.service'
import { BuildingTypeCategory } from '../entities/building-type-category.model'
import { BuildingType } from '../entities/building-type.model'
import { Translatable } from '../entities/translatable.model'
import { selectSelectedLanguage } from '../book/redux/state/language.state'
import { select, Store } from '@ngrx/store'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BookState } from '../book/redux/book.state'
import { ProfileService } from '../layouts'
import { Release } from '../entities/release.model'

@UntilDestroy()
@Component({
    selector: 'jhi-home',
    templateUrl: './home.component.html',
    styleUrls: [
        'home.scss'
    ]
})
export class HomeComponent implements OnInit {

    public isAuthenticated = false

    public isLoading = false

    /*
    Tab fields.
     */
    public releaseContexts: ReleaseContext[] = []

    public releases: ReleaseHomeCell[] = []

    public tabMap: Map<BuildingTypeCategory, Map<BuildingType, ReleaseContext[]>>

    public selectedBuildingTypeCategory: BuildingTypeCategory

    public selectedBuildingType: BuildingType

    /*
    Dropdown fields.
     */
    @ViewChild('dropdownLanguage', { read: ElementRef }) dropdownLanguage: ElementRef

    @ViewChild('dropdownCountry', { read: ElementRef }) dropdownCountry: ElementRef

    public showLanguageDropdownMenu: boolean

    public showCountryDropdownMenu: boolean

    public selectedLanguage: Language

    public selectedCountry: Country

    public languages: Language[] = []

    public countries: Country[] = []

    public selectedLocale: string

    public lidl = false

    /*
    Search fields.
     */
    public searchWord: string

    constructor(private readonly oauth2Service: OAuth2Service,
                private readonly languageService: LanguageService,
                private readonly countryService: CountryService,
                private readonly releaseService: ReleaseService,
                private readonly store: Store<BookState>,
                private readonly titleService: TitleService,
                private readonly profileService: ProfileService, ) {
    }

    ngOnInit() {
        this.titleService.setHomepageTitle()
        this.oauth2Service.authenticationState.subscribe((state) => {
            if (state) {
                this.isAuthenticated = true
                this.loadLanguages()
                this.loadCountries()
                this.loadReleaseContexts()
            } else {
                this.isAuthenticated = false
            }
        })

        this.store
            .pipe(
                select(selectSelectedLanguage),
                untilDestroyed(this)
            )
            .subscribe((selectedLanguage) => this.selectedLocale = selectedLanguage)

        this.profileService.getProfileInfo().subscribe((profileInfo) => {
            this.lidl = profileInfo.lidl
        })
    }

    /**
     *
     * Load all languages.
     */
    loadLanguages() {
        this.languageService
            .query()
            .pipe(untilDestroyed(this))
            .subscribe((languages) => this.languages = languages)
    }

    /**
     *
     * Load all countries.
     */
    loadCountries() {
        this.countryService
            .query()
            .pipe(untilDestroyed(this))
            .subscribe((countries) => this.countries = countries)
    }

    /**
     *
     * Load all release contexts.
     */
    loadReleaseContexts() {
        this.isLoading = true
        this.releaseService.query().pipe(
            untilDestroyed(this)
        ).subscribe((releaseContexts) => {
            if (releaseContexts.length !== 0) {
                this.releaseContexts = releaseContexts
                this.tabMap = this.createTabs(releaseContexts)
                this.selectInitialTabs()
            }
            this.isLoading = false
        })
    }

    /**
     *
     * Search for release contexts.
     */
    searchReleaseContexts() {
        let filteredReleaseContexts = this.releaseContexts
        if (this.searchWord) {
            filteredReleaseContexts = filteredReleaseContexts.filter((releaseContext) =>
                releaseContext.latestPublishedReleases.some((release) => release.name.toLocaleLowerCase().includes(this.searchWord.toLocaleLowerCase())) ||
                releaseContext.project.name.toLocaleLowerCase().includes(this.searchWord.toLocaleLowerCase()))
        }

        if (this.selectedLanguage) {
            filteredReleaseContexts = filteredReleaseContexts.filter((releaseContext) => releaseContext.language.originalId === this.selectedLanguage.originalId)
        }

        if (this.selectedCountry) {
            filteredReleaseContexts = filteredReleaseContexts.filter((releaseContext) => releaseContext.project.country.originalId === this.selectedCountry.originalId)
        }

        if (filteredReleaseContexts.length === 0) {
             this.tabMap = null
        } else {
            this.tabMap = this.createTabs(filteredReleaseContexts)
            this.selectInitialTabs()
        }
    }

    /**
     *
     * Resetting the tabs after a serach.
     */
    reset() {
        this.searchWord = null
        this.selectedLanguage = null
        this.selectedCountry = null
        if (this.releaseContexts.length !== 0) {
            this.tabMap = this.createTabs(this.releaseContexts)
            this.selectInitialTabs()
        }
    }

    /**
     *
     * Select a building type category.
     * @param {BuildingTypeCategory} buildingTypeCategory
     */
    selectBuildingTypeCategory(buildingTypeCategory: BuildingTypeCategory) {
        this.selectedBuildingTypeCategory = buildingTypeCategory
        this.selectedBuildingType = Array.from(this.tabMap.get(this.selectedBuildingTypeCategory).keys())[0]
    }

    /**
     *
     * Select a building type.
     * @param {BuildingType} buildingType
     */
    selectBuildingType(buildingType: BuildingType) {
        this.selectedBuildingType = buildingType
    }

    /**
     *
     * Get all building type categories.
     * @returns {BuildingTypeCategory[]}
     */
    getBuildingTypeCategories(): BuildingTypeCategory[] {
        return Array.from(this.tabMap.keys())
    }

    /**
     *
     * Get all building types.
     * @returns {BuildingType[]}
     */
    getBuildingTypes(): BuildingType[] {
        return Array.from(
            this.tabMap
                .get(this.selectedBuildingTypeCategory)
                .keys()
        )
    }

    /**
     *
     * Get all release contexts.
     * @returns {ReleaseContext[]}
     */
    getReleaseCells(): ReleaseHomeCell[] {
        const result = []
        const releaseContexts = this.tabMap
            .get(this.selectedBuildingTypeCategory)
            .get(this.selectedBuildingType)
        releaseContexts.forEach((releaseContext) => {
            releaseContext.latestPublishedReleases.forEach((release) => result.push({ releaseContext, release }))
        })
        return result
    }

    /**
     *
     * Setting language and closing the dropdown.
     * @param {Language} language
     */
    setLanguage(language: Language) {
        this.toggleLanguageDropdownMenu()
        this.selectedLanguage = language
    }

    /**
     *
     * Setting country and closing the dropdown.
     * @param {Country} country
     */
    setCountry(country: Country) {
        this.toggleCountryDropdownMenu()
        this.selectedCountry = country
    }

    /**
     *
     * Show/hide the language drop down.
     */
    toggleLanguageDropdownMenu() {
        this.showLanguageDropdownMenu = !this.showLanguageDropdownMenu
    }

    /**
     *
     * Show/hide the country dropdown.
     */
    toggleCountryDropdownMenu() {
        this.showCountryDropdownMenu = !this.showCountryDropdownMenu
    }

    /**
     *
     * Extract the correct translation based on the selected language.
     * @param {Translatable} entity - building type or building type category.
     * @returns {string} - translated name.
     */
    getTranslation(entity: Translatable): string {

        if (entity.translations) {
            const relevantTranslation = entity.translations.find((translation) => translation.locale.includes(this.selectedLocale))
            if (relevantTranslation && relevantTranslation.text) {
                return relevantTranslation.text
            }
        }

        return entity.name
    }

    /**
     *
     * When you click outside of the element hide the dropdowns.
     * @param event - outside click.
     */
    @HostListener('document:click', ['$event'])
    onClickOutside(event) {
        if (!this.isAuthenticated) {
            return
        }

        if (!this.dropdownLanguage.nativeElement.contains(event.target)) {
            if (this.showLanguageDropdownMenu) {
                this.toggleLanguageDropdownMenu()
            }
        }

        if (!this.dropdownCountry.nativeElement.contains(event.target)) {
            if (this.showCountryDropdownMenu) {
                this.toggleCountryDropdownMenu()
            }
        }
    }

    encodeBase64(value: string): string {
        return btoa(value)
    }

    private createTabs(releaseContexts: ReleaseContext[]) {
        let tabMap = new Map<BuildingTypeCategory, Map<BuildingType, ReleaseContext[]>>()
        // Build the map
        releaseContexts.forEach((releaseContext) => {
            const buildingType = releaseContext.project.buildingType
            const buildingTypeCategory = releaseContext.project.buildingType.buildingTypeCategory

            let existingBuildingTypeCategory = Array.from(tabMap.keys()).find((buildingTypeCategoryKey) => buildingTypeCategoryKey.originalId === buildingTypeCategory.originalId)
            if (!existingBuildingTypeCategory) {
                tabMap.set(buildingTypeCategory, new Map<BuildingType, ReleaseContext[]>())
                existingBuildingTypeCategory = buildingTypeCategory
            }

            const buildingTypeMap = tabMap.get(existingBuildingTypeCategory)
            let existingBuildingType = Array.from(buildingTypeMap.keys()).find((buildingTypeKey) => buildingTypeKey.originalId === buildingType.originalId)
            if (!existingBuildingType) {
                buildingTypeMap.set(buildingType, [])
                existingBuildingType = buildingType
            }

            const releaseContextList = buildingTypeMap.get(existingBuildingType)
            if (releaseContextList.findIndex((existingReleaseContext) => existingReleaseContext.id === releaseContext.id) === -1) {
                releaseContextList.push(releaseContext)
            }
        })

        // Sort the maps
        tabMap = new Map([...Array.from(tabMap.entries())].sort((a, b) => a[0].order - b[0].order))
        Array.from(tabMap.keys()).forEach(
            (key) =>  tabMap.set(
                key,
                new Map([...Array.from(tabMap.get(key))].sort((a, b) => a[0].order - b[0].order))
            )
        )

        return tabMap
    }

    private selectInitialTabs() {
        if (this.tabMap) {
            this.selectedBuildingTypeCategory = Array.from(this.tabMap.keys())[0]
            this.selectedBuildingType = Array.from(this.tabMap.get(this.selectedBuildingTypeCategory).keys())[0]
        }
    }

    public login() {
        this.oauth2Service.login()
    }

    // Map language locales to a country locale in order to render a flag
    handleLanguageLocale(locale: string) {

        switch (locale.toLowerCase()) {
            case 'cs':
                return 'cz'
            case 'en-au':
                return 'au'
            default:
                return locale
        }

    }
}

export interface ReleaseHomeCell {
    releaseContext: ReleaseContext,
    release: Release
}
