import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../../app.constants'
import { map } from 'rxjs/operators'
import { CraftTableItem } from './craft-table-item.model'
import { MaterialGroupTableItem } from './material-group-table-item.model'
import { AttachmentCategoryTableItem } from './attachment-category-table-item.model'

@Injectable()
export class TableOfContentsService {

    constructor(private readonly http: HttpClient) {

    }

    public queryCrafts(releaseId: number): Observable<CraftTableItem[]> {
        return this.http.get<CraftTableItem[]>(`${SERVER_API_URL}/api/releases/${releaseId}/table-of-contents`, { observe: 'body' })
            .pipe(map((tableOfContentsItem: any) => this.convertCraftFromServer(tableOfContentsItem)))
    }

    public queryAttachmentCategories(releaseId: number): Observable<AttachmentCategoryTableItem[]> {
        return this.http.get<CraftTableItem[]>(`${SERVER_API_URL}/api/releases/${releaseId}/attachment-categories`, { observe: 'body' })
            .pipe(map((attachmentCategories: any) => this.convertAttachmentCategoryFromServer(attachmentCategories)))
    }

    private convertCraftFromServer(crafts: any): CraftTableItem[] {
        for (let c = 0; c < crafts.length; c++) {
            if (crafts[c].materialGroups) {
                for (let m = 0; m < crafts[c].materialGroups.length; m++) {
                    crafts[c].materialGroups[m] = Object.assign(new MaterialGroupTableItem(), crafts[c].materialGroups[m])
                }
            }

            crafts[c] = Object.assign(new CraftTableItem(), crafts[c])
        }

        return crafts
    }

    private convertAttachmentCategoryFromServer(attachmentCategories: any): AttachmentCategoryTableItem[] {
        for (let c = 0; c < attachmentCategories.length; c++) {
            const attachmentCategory = attachmentCategories[c]
            attachmentCategories[c] = new AttachmentCategoryTableItem(attachmentCategory.originalId, attachmentCategory.heading)
        }

        return attachmentCategories
    }
}
