import { DOCUMENT } from '@angular/common'
import { ElementRef, Inject, Pipe, PipeTransform, Renderer2, SecurityContext } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { cloneDeep } from 'lodash'

export enum TagStatus {
    Opened = 'opened',
    Closed = 'closed'
}

enum CollapsibleIcon {
    Opened = 'fa fa-angle-up',
    Closed = 'fa fa-angle-down'
}

export const ENCODED_START_OPENED_COLLAPSIBLE = '<ins>&lt;[START_OPENED_COLLAPSIBLE]&gt;</ins>'
export const ENCODED_START_CLOSED_COLLAPSIBLE = '<ins>&lt;[START_CLOSED_COLLAPSIBLE]&gt;</ins>'
export const ENCODED_END_COLLAPSIBLE = '<ins>&lt;[END_COLLAPSIBLE]&gt;</ins>'

@Pipe({
    name: 'dynamicCollapsible'
})
export class DynamicCollapsiblePipe implements PipeTransform {
    private toggleHelpers = []
    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly elementRef: ElementRef,
        private readonly renderer: Renderer2,
        private readonly sanitizer: DomSanitizer
    ) {
    }

    transform(inputText: string): any {
        if (!inputText) {
            return ''
        } else if (!inputText.includes(ENCODED_END_COLLAPSIBLE)) {
            return inputText
        } else {
            this.cleanChildrenNodes()

            inputText = this.reorderTags(inputText)

            const blocksToBeCallapsible: string[] = this.collectToBeCollapsibleBlocks(inputText)

            const normalBlocks: string[] = this.collectNormalBlocks(blocksToBeCallapsible, inputText)

            this.createCollapsibleElements(blocksToBeCallapsible, normalBlocks)

            return ''
        }
    }

    private cleanChildrenNodes() {

        if (!!this.elementRef.nativeElement.parentNode && !!this.elementRef.nativeElement.parentNode.children.length) {
            Array.prototype.slice.call(this.elementRef.nativeElement.parentNode.children).forEach((child: any) => {
                child.innerHTML = ''
            })
        }
    }

    private collectNormalBlocks(blocksToBeCallapsible: string[], inputText: string) {
        const blocksToBeCallapsibleWithoutTags = blocksToBeCallapsible.map((block: string) => this.removeCollapsibleTags(block))

        const normalBlocks = []
        let content = cloneDeep(inputText)

        // Extract First Normal Block
        const firstpart = content.substring(0, content.indexOf(blocksToBeCallapsibleWithoutTags[0]))
        normalBlocks.push(firstpart)
        content = content.replace(firstpart, '')

        // Extract normal blocks
        for (let index = 0; index < blocksToBeCallapsible.length - 1; index++) {
            const element = blocksToBeCallapsibleWithoutTags[index]
            const nextElement = blocksToBeCallapsibleWithoutTags[index + 1]
            let blockBetweenCollapsibles = content.substring(content.indexOf(element), content.indexOf(nextElement))
            blockBetweenCollapsibles = blockBetweenCollapsibles.replace(element, '')
            normalBlocks.push(blockBetweenCollapsibles)
        }

        let lastPart = content.substring(content.indexOf(blocksToBeCallapsibleWithoutTags[blocksToBeCallapsible.length - 1]))
        lastPart = lastPart.replace(blocksToBeCallapsibleWithoutTags[blocksToBeCallapsible.length - 1], '')
        normalBlocks.push(lastPart)
        return normalBlocks
    }

    private reorderTags(inputText: string): string {
        const htmlTags = ['<h1>', '<h2>', '<h3>', '<h4>', '<h5>', '<strong>', '<p>']
        const startingTags = [ENCODED_START_OPENED_COLLAPSIBLE, ENCODED_START_CLOSED_COLLAPSIBLE]
        startingTags.forEach((tag: string) => htmlTags
            .forEach((htmlTag: string) => inputText = inputText.replace(htmlTag + tag, tag + htmlTag)))

        return inputText
    }

    private createCollapsibleElements(blocksToBeCallapsible: string[], normalBlocks: string[]) {
        blocksToBeCallapsible = blocksToBeCallapsible.reverse()
        normalBlocks = normalBlocks.reverse()

        this.elementRef.nativeElement.innerHTML = ''

        for (let index = 0; index < blocksToBeCallapsible.length + normalBlocks.length; index++) {

            // NOT EXPANDIBLE
            if (index < normalBlocks.length) {
                this.appendHtmlToParentNode(this.removeCollapsibleTags(normalBlocks[index]))
            }

            // EXPANDIBLE
            if (index < blocksToBeCallapsible.length) {
                const callapsibleText = this.removeCollapsibleTags(blocksToBeCallapsible[index])

                const textContainer = this.createExpandableContainer(callapsibleText, index, blocksToBeCallapsible[index].includes(ENCODED_START_OPENED_COLLAPSIBLE))
                this.createExpandButton(textContainer, index, blocksToBeCallapsible[index].includes(ENCODED_START_OPENED_COLLAPSIBLE))
            }
        }
    }

    private appendHtmlToParentNode(htmlString: string) {
        let tmpHtmlString = this.sanitizer.sanitize(SecurityContext.NONE, htmlString)
        const template = document.createElement('template')
        tmpHtmlString = tmpHtmlString.trim() // Never return a text node of whitespace as the result
        template.innerHTML = tmpHtmlString
        template.content.childNodes
            .forEach((node: ChildNode) => this.renderer.insertBefore(this.elementRef.nativeElement.parentNode, node.parentNode, this.elementRef.nativeElement.nextSibling))
    }

    private collectToBeCollapsibleBlocks(inputText: string): string[] {
        const blocksToBeCallapsible: string[] = []
        let content = cloneDeep(inputText)

        while (content.includes(ENCODED_END_COLLAPSIBLE) && (content.includes(ENCODED_START_OPENED_COLLAPSIBLE) || content.includes(ENCODED_START_CLOSED_COLLAPSIBLE))) {
            const nextTag = this.getNextTag(content)

            const singleBlockToBeCallapsible = content.substring(
                content.indexOf(nextTag === TagStatus.Opened ? ENCODED_START_OPENED_COLLAPSIBLE : ENCODED_START_CLOSED_COLLAPSIBLE),
                content.indexOf(ENCODED_END_COLLAPSIBLE)
            ) + ENCODED_END_COLLAPSIBLE

            blocksToBeCallapsible.push(singleBlockToBeCallapsible)
            content = content.replace(singleBlockToBeCallapsible, '')
        }
        return blocksToBeCallapsible
    }

    private removeCollapsibleTags(block: string): string {
        return block.replace(ENCODED_START_OPENED_COLLAPSIBLE, '').replace(ENCODED_START_CLOSED_COLLAPSIBLE, '').replace(ENCODED_END_COLLAPSIBLE, '')
    }

    private createExpandableContainer(textToBeExapndable: string, blockIndex: number, isInitiallyExpnded: boolean): any {
        const textContainerId = 'text-container-' + blockIndex
        const textContainer = this.renderer.createElement('div')
        this.renderer.setProperty(textContainer, 'id', textContainerId)

        const text = this.renderer.createText('DUMMY_INIT_TEXT')

        this.renderer.appendChild(textContainer, text)
        this.renderer.setStyle(textContainer, 'visibility', isInitiallyExpnded ? 'visible' : 'hidden')
        this.renderer.setStyle(textContainer, 'height', isInitiallyExpnded ? 'auto' : '0px')

        this.renderer.insertBefore(this.elementRef.nativeElement.parentNode, textContainer, this.elementRef.nativeElement.nextSibling)

        this.document.getElementById(textContainerId).innerHTML = textToBeExapndable

        return textContainer
    }

    private createExpandButton(textContainer: any, blockIndex: number, isInitiallyExpnded: boolean): any {
        const div = this.renderer.createElement('div')
        const button = this.renderer.createElement('button')
        this.renderer.setAttribute(button, 'class', 'btn')
        this.renderer.setStyle(button, 'width', '100%')
        this.renderer.setStyle(button, 'background-color', 'whitesmoke')
        this.renderer.setStyle(button, 'text-align', 'end')
        const icon = this.renderer.createElement('span')
        this.renderer.setAttribute(icon, 'class', isInitiallyExpnded ? CollapsibleIcon.Opened : CollapsibleIcon.Closed)
        this.renderer.setStyle(icon, 'font-size', '20px')
        this.renderer.appendChild(button, icon)
        this.renderer.appendChild(div, button)
        this.renderer.insertBefore(this.elementRef.nativeElement.parentNode, div, this.elementRef.nativeElement.nextSibling)
        this.toggleHelpers.push(!isInitiallyExpnded)
        this.renderer.listen(button, 'click', () => this.toggle(textContainer, blockIndex, icon))

        return div
    }

    private toggle(textContainer: any, blockIndex: number, buttonIcon: any) {
        this.toggleHelpers[blockIndex] = !this.toggleHelpers[blockIndex]
        this.renderer.setStyle(textContainer, 'visibility', this.toggleHelpers[blockIndex] ? 'hidden' : 'visible')
        this.renderer.setStyle(textContainer, 'height', this.toggleHelpers[blockIndex] ? '0px' : 'auto')

        this.renderer.setAttribute(buttonIcon, 'class', this.toggleHelpers[blockIndex] ? CollapsibleIcon.Closed : CollapsibleIcon.Opened)
    }

    private getNextTag(content: string): TagStatus {
        const openedCollapsibleExists = content.includes(ENCODED_START_OPENED_COLLAPSIBLE)
        const closedCollapsibleExists = content.includes(ENCODED_START_CLOSED_COLLAPSIBLE)

        const indexOfOpened = content.indexOf(ENCODED_START_OPENED_COLLAPSIBLE)
        const indexOfClosed = content.indexOf(ENCODED_START_CLOSED_COLLAPSIBLE)

        let nextTag: TagStatus

        if (openedCollapsibleExists && !closedCollapsibleExists) {
            nextTag = TagStatus.Opened
        } else if (!openedCollapsibleExists && closedCollapsibleExists) {
            nextTag = TagStatus.Closed
        } else if (openedCollapsibleExists && closedCollapsibleExists && indexOfOpened < indexOfClosed) {
            nextTag = TagStatus.Opened
        } else if (openedCollapsibleExists && closedCollapsibleExists && indexOfOpened > indexOfClosed) {
            nextTag = TagStatus.Closed
        }
        return nextTag
    }

}
