import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core'
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { select, Store } from '@ngrx/store'
import { combineLatest, Observable, of } from 'rxjs'
import { filter, switchMap, take } from 'rxjs/operators'
import { File } from '../../entities/file.model'
import { Manufacturer } from '../../entities/manufacturer.model'
import { Product } from '../../entities/product.model'
import { OAuth2Service } from '../../shared/auth/oauth2.service'
import { TitleService } from '../../shared/title/title.service'
import { contentContainerRequest$ } from '../common/content-container-request'
import { StartLoadingAction, StopLoadingAction } from '../redux/actions/loading.action'
import { ToggleSidebarAction } from '../redux/actions/release.action'
import { BookState } from '../redux/book.state'
import { selectQueryParams, shouldShowSidebar } from '../redux/state'
import { ManufacturerService } from './manufacturer.service'

@UntilDestroy()
@Component({
    selector: 'manufacturer-detail',
    templateUrl: './manufacturer-detail.component.html'
})
export class ManufacturerDetailComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input()
    public manufacturer: Manufacturer

    public queryParams = {}

    public showSidebar$: Observable<boolean>

    public isExternalUser = false

    private currentReleaseId: any

    private manufacturerId: any

    private selectedReleaseId: any

    @ViewChildren(NgbAccordion) accordions !: QueryList<NgbAccordion>

    constructor(
        private readonly manufacturerService: ManufacturerService,
        private readonly titleService: TitleService,
        private readonly store: Store<BookState>,
        private readonly oAuth2Service: OAuth2Service
    ) {
        this.manufacturer = { products: [] }
    }

    ngOnInit(): void {
        this.checkIfUserExternal()

        this.showSidebar$ = this.store.pipe(select(shouldShowSidebar))
        const queryParams$ = this.store.pipe(select(selectQueryParams), filter((params) => !!params))

        contentContainerRequest$(this.store)
            .pipe(
                switchMap(([params, currentRelease, selectedRelease]) => {
                    if (params.id && currentRelease.id) {

                        this.manufacturerId = parseInt(params.id, 10)
                        this.currentReleaseId = currentRelease.id

                        // We only need to call the backend if the manufacturer has actually changed or a release is selected
                        if (!this.manufacturer || this.manufacturerId !== this.manufacturer.originalId || selectedRelease) {

                            if (selectedRelease && selectedRelease.id !== this.currentReleaseId) {
                                this.selectedReleaseId = selectedRelease.id
                                return this.loadManufacturer(this.currentReleaseId, this.manufacturerId, selectedRelease.id)
                            } else {
                                return this.loadManufacturer(this.currentReleaseId, this.manufacturerId)
                            }

                        } else {
                            this.titleService.setTitle(this.manufacturer.name)
                        }

                        return of(this.manufacturer)
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe((manufacturer: Manufacturer) => {
                this.handleManufacturer(manufacturer)
            })

        queryParams$.pipe(untilDestroyed(this)).subscribe((params) => {
            this.queryParams = params
        })

    }

    private checkIfUserExternal(): void {
        this.oAuth2Service.userIdentity.pipe(take(1)).subscribe((account) => {
            const authorities: string[] = account['authorities']
            if (authorities.some((authority: string) => authority.toLowerCase().includes('extern'))) {
                this.isExternalUser = true
            }
        })
    }

    ngAfterViewInit() {

        combineLatest([this.showSidebar$, this.accordions.changes]).pipe(untilDestroyed(this)).subscribe(([showSidebar, accordions]) => {

            setTimeout(() => {
                accordions.forEach((accordion) => {

                    if (!showSidebar) {
                        accordion.closeOtherPanels = false
                        accordion.expandAll()
                    } else {
                        accordion.closeOtherPanels = true
                    }
                })
            }, 0)

        })

    }

    ngOnDestroy(): void {
        this.store.dispatch(new StopLoadingAction())
    }

    toggleSidebar() {
        this.store.dispatch(new ToggleSidebarAction())
    }

    trackId(index: number, item: Product) {
        return item.id
    }

    trackChecksum(index: number, item: File) {
        return item.checksum
    }

    loadAll() {
        if (this.selectedReleaseId && this.selectedReleaseId !== this.currentReleaseId) {
            return this.loadData(this.currentReleaseId, this.manufacturerId, this.selectedReleaseId).subscribe((manufacturer: Manufacturer) => {
                this.handleManufacturer(manufacturer)
            })
        } else {
            return this.loadData(this.currentReleaseId, this.manufacturerId).subscribe((manufacturer: Manufacturer) => {
                this.handleManufacturer(manufacturer)
            })
        }
    }

    private loadManufacturer(releaseId: number, originalId: number, selectedReleaseId?: number) {

        this.store.dispatch(new StopLoadingAction())
        this.store.dispatch(new StartLoadingAction())

        return this.loadData(releaseId, originalId, selectedReleaseId)
    }

    private loadData(releaseId: number, originalId: number, selectedReleaseId?: number): Observable<Manufacturer> {
        return this.manufacturerService.find(releaseId, originalId, selectedReleaseId)
    }

    private handleManufacturer(manufacturer: Manufacturer) {
        this.manufacturer = manufacturer

        this.titleService.setTitle(manufacturer.name)

        this.store.dispatch(new StopLoadingAction())
    }

}
