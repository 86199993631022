import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Room } from '../../entities/room.model'
import { SERVER_API_URL } from '../../app.constants'
import { map } from 'rxjs/operators'
import { Material } from '../../entities'

@Injectable()
export class RoomService {

    constructor(private readonly httpClient: HttpClient) {
    }

    find(releaseId: number, id: number, selectedReleaseId?: number): Observable<Room> {
        let params = new HttpParams()
        if (selectedReleaseId) {
            params = params.append('originalReleaseId', '' + selectedReleaseId)
        }

        return this.httpClient
            .get<Room>(`${SERVER_API_URL}/api/releases/${releaseId}/rooms/${id}`, { params })
            .pipe(map((room) => Object.assign(new Room(), room)))
    }

    getMaterialsRelated(releaseId: number, originalId: number): Observable<Material[]> {
        return this.httpClient
            .get<Material[]>(`${SERVER_API_URL}/api/releases/${releaseId}/rooms/${originalId}/related`)
    }
}
