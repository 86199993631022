import { Routes } from '@angular/router'

import { ManufacturerDetailComponent } from './manufacturer-detail.component'

export const manufacturerRoutes: Routes = [
    {
        path: 'manufacturer/:id',
        component: ManufacturerDetailComponent
    }
]
