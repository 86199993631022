import { AttachmentCategoryTableItem } from './attachment-category-table-item.model'

export class AttachmentCategoryHolder {
    constructor(public attachmentCategories?: AttachmentCategoryTableItem[],
                public isCollapsed?: boolean) {
        this.isCollapsed = true
        this.attachmentCategories = []
    }

    hasItems(): boolean {
        return this.attachmentCategories.length > 0
    }
}
