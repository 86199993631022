import { Component, OnInit } from '@angular/core'
import { LocalStorageService } from 'ngx-webstorage'
@Component({
    selector: 'cookie-consent',
    templateUrl: './cookie-consent.component.html',
    styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {

    isClosed = false

    public readonly cookieConsentKeyName = '_closedCookieConsent'

    constructor(
        private readonly localStorageService: LocalStorageService
    ) { }

    ngOnInit(): void {
        if (this.localStorageService.retrieve(this.cookieConsentKeyName)) {
            this.isClosed = true
        }
    }

    public close(): void {
        this.localStorageService.store(this.cookieConsentKeyName, '1')
        this.isClosed = true
    }

}
