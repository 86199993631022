import { MaterialGroupTableItem } from './material-group-table-item.model'

export class CraftTableItem {
    constructor(
        public originalId?: number,
        public heading?: string,
        public isCollapsed?: boolean,
        public materialGroups?: MaterialGroupTableItem[]
    ) {
        this.isCollapsed = true
        this.materialGroups = []
    }

    get hasEmptyHeading() {
        return !this.heading || this.heading.trim() === ''
    }
}
