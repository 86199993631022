import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable()
export class VersionService {

    private readonly versionUrl = SERVER_API_URL + '/management/info'
    private readonly buildVersionUrl = SERVER_API_URL + '/api/build-version'
    constructor(private readonly httpClient: HttpClient) {
    }

    getVersion(): Observable<any> {
        const headers = new HttpHeaders({'Accept': 'application/json'})
        return this.httpClient.get(this.versionUrl, {headers, responseType: 'json'})
    }

    getBuildVersion(): Observable<any> {
        return this.httpClient.get(this.buildVersionUrl, {responseType: 'text'})
    }
}
