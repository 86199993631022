import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { RouterModule } from '@angular/router'

import { JhipsterSharedModule } from '../shared'
import { PageViewsReleasesComponent } from './page-views-releases.component'
import { pageViewReleasesRoutes } from './page-views-releases.route'
import { PageViewsReleasesService } from './page-views-releases.service'
@NgModule({
    imports: [
        JhipsterSharedModule,
        RouterModule.forChild(pageViewReleasesRoutes)
    ],
    declarations: [
        PageViewsReleasesComponent,
    ],
    entryComponents: [
    ],
    providers: [
        PageViewsReleasesService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageViewsReleasesModule { }
