import { BookNavigationMode } from '../../navigation/book-navigation-mode'
import { Room, RoomGroup } from '../../../entities'
import { CraftTableItem } from '../../navigation/table-of-contents/craft-table-item.model'
import { createFeatureSelector, createSelector } from '@ngrx/store'

export interface BookNavigationState {
    roombook: {
        loaded: boolean
        data: RoomGroup[]
    },
    tableOfContents: {
        loaded: boolean,
        data: CraftTableItem[]
    },
    attachmentCategories: {
        loaded: boolean,
        data: CraftTableItem[]
    },
    mode: BookNavigationMode
    selectedRoom: Room,
    collapsed: boolean
}

export const getBookNavigationState = createFeatureSelector<BookNavigationState>('bookNavigation')

export const selectBookNavigationOpenState = createSelector(
    getBookNavigationState,
    (state: BookNavigationState) => !state.collapsed
)

export const selectBookNavigationMode = createSelector(
    getBookNavigationState,
    (state: BookNavigationState) => state.mode
)

export const getRoombookData = createSelector(
    getBookNavigationState,
    (state: BookNavigationState) => state.roombook
)

export const getTableOfContentsData = createSelector(
    getBookNavigationState,
    (state: BookNavigationState) => state.tableOfContents
)

export const hasRoombookData = createSelector(
    getBookNavigationState,
    (state: BookNavigationState) => state.roombook.loaded && state.roombook.data.length > 0
)
export const hasTableOfContentsData = createSelector(
    getBookNavigationState,
    (state: BookNavigationState) => state.tableOfContents.loaded && state.tableOfContents.data.length > 0
)
