import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { switchMap } from 'rxjs/operators'
import { ChangeLanguageAction, LanguageActionType } from '../actions/language.action'
import { JhiLanguageService } from 'ng-jhipster'
import { LanguageChoiceService } from '../../../shared/language/language-choice.service'
import { of } from 'rxjs'

@Injectable({
    providedIn: 'root'
})
export class LanguageEffects {

    @Effect({dispatch: false})
    changeLanguage$ = this.actions$
        .pipe(
            ofType(LanguageActionType.CHANGE),
            switchMap((action: ChangeLanguageAction) => {
                const languageKey = action.payload.newLanguage
                this.languageChoiceService.setPreferredLanguage(languageKey)
                this.languageService.changeLanguage(languageKey)
                return of({type: 'NO_ACTION'})
            })
        )

    constructor(private readonly actions$: Actions,
                private readonly languageService: JhiLanguageService,
                private readonly languageChoiceService: LanguageChoiceService) {
    }

}
