import { Routes } from '@angular/router'
import { ReleaseComponent } from './release.component'
import { materialRoutes } from './material/material.route'
import { SearchResultsComponent } from './search-results/search-results.component'
import { craftRoutes } from './craft/craft.route'
import { roomRoutes } from './room/room.route'
import { Roles } from '../shared/auth/roles'
import { UserRouteAccessService } from '../shared/auth/user-route-access-service'
import { materialGroupRoutes } from './material-group/material-group.route'
import { attachmentCategoryRoutes } from './attachment-category/attachment-category.route'
import { landingPageRoutes } from './landing-page/landing-page.route'
import { changelogRoutes } from './changelog'
import { revitRoutes } from './revit/revit.route'
import { manufacturerRoutes } from './manufacturer/manufacturer.route'

export const bookRoutes: Routes = [
    {
        path: ':project/:language/:construction',
        component: ReleaseComponent,
        data: {
            authorities: Roles.VIEWER
        },
        canActivate: [UserRouteAccessService],
        children: [
            ...attachmentCategoryRoutes,
            ...materialRoutes,
            ...craftRoutes,
            ...roomRoutes,
            ...materialGroupRoutes,
            ...landingPageRoutes,
            ...changelogRoutes,
            ...revitRoutes,
            ...manufacturerRoutes,
            {
                path: 'search',
                component: SearchResultsComponent
            }
        ]
    },
    {
        path: 'gallery',
        outlet: 'overlay',
        loadChildren: () => import('./gallery/gallery.module').then((m) => m.GalleryWrapperModule)
    },
    {
        path: 'pdf',
        outlet: 'overlay',
        loadChildren: () => import('./pdf-overlay/pdf-overlay.module').then((m) => m.PdfOverlayModule)
    }

]
