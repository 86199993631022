import { Product } from '../../entities/product.model'

export type ProductMap = Map<string, Map<string, Product[]>>

export class ProductUtil {
    /**
     * Groups relations and products into single map.
     * @param products - Map<Relation, Map<ProductGroup, Product[]>
     */
    public static groupProductsByRelation(products: Product[]): ProductMap {
        const productMap = new Map<string, Map<string, Product[]>>()
        products.forEach((product) => {
            const relationType = product.relationType
            if (!productMap.has(relationType)) {
                productMap.set(relationType, new Map<string, Product[]>())
            }

            const productGroup = product.productGroup.name
            const productGroupMap = productMap.get(relationType)
            if (!productGroupMap.has(productGroup)) {
                productGroupMap.set(productGroup, [])
            }

            productGroupMap.get(productGroup).push(product)
        })

        return ProductUtil.sortRelations(productMap)
    }

    private static sortRelations(productMap: ProductMap) {
        const sortedEntries = Array.from(productMap).sort((p1, p2) => {
            const relationOne = getOrder(p1[0])
            const relationTwo = getOrder(p2[0])
            return relationOne - relationTwo
        })

        return new Map<string, Map<string, Product[]>>(sortedEntries)

        function getOrder(relation) {
            switch (relation) {
                case 'MANDATORY':
                    return 0
                case 'REFERENCE':
                    return 1
                case 'BLOCKED':
                    return 2
                default:
                    return -1
            }
        }
    }
}
