import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { Params } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { select, Store } from '@ngrx/store'
import { NgProgressComponent } from '@ngx-progressbar/core'
import { combineLatest } from 'rxjs'
import { filter, map, skipWhile, switchMap, tap } from 'rxjs/operators'
import { ReleaseContext } from '../entities/release-context.model'
import { Release } from '../entities/release.model'
import { TitleService } from '../shared'
import { ClearReleaseDataAction, LoadReleaseDataAction, SetReferenceReleaseAction } from './redux/actions/release.action'
import { BookState } from './redux/book.state'
import { selectQueryParams, selectRouteParams } from './redux/state'
import { BookNavigationState } from './redux/state/book-navigation.state'
import { isLoading as isLoadingSelector, isLoadingPdf } from './redux/state/loading.state'
import { getCurrentRelease } from './redux/state/release.state'
import { ReleaseService } from './release.service'

@UntilDestroy()
@Component({
    selector: 'jhi-release',
    templateUrl: 'release.component.html'
})
export class ReleaseComponent implements OnInit, AfterViewInit {

    bookNavigationCollapsed: boolean

    public currentRelease$

    public releaseContext: ReleaseContext
    public queryParams: Params

    @ViewChild('loadingBar') progressBar: NgProgressComponent

    constructor(
        private readonly store: Store<BookState>,
        private readonly releaseService: ReleaseService,
        private readonly titleService: TitleService) {

        this.currentRelease$ = this.store.pipe(select(getCurrentRelease))
    }

    ngOnInit() {
        this.store.pipe(
            select('bookNavigation'),
            untilDestroyed(this)
        ).subscribe(({ mode, collapsed }: BookNavigationState) => {
            localStorage.setItem('bookNavigationMode', mode)
            this.bookNavigationCollapsed = collapsed
        })
        this.setReferenceRelease()

        const params$ = this.getParams()
        const queryParams$ = this.getQueryParams()

        combineLatest([params$, queryParams$])
            .pipe(
                // Make sure we clear the release data every time the release context changes
                // this is necessary to avoid problems with the multi-book search
                tap(() => this.store.dispatch(new ClearReleaseDataAction())),
                switchMap(([params, queryParams]) => {
                    return this.releaseService
                        .findReleaseContextByUrlFragments(params.project, params.language, params.construction)
                        .pipe(map((releaseContext) => [releaseContext, queryParams]))
                }),
                untilDestroyed(this)
            )
            .subscribe(([releaseContext, queryParams]) => {
                this.releaseContext = releaseContext
                const releaseId = parseInt(this.decodeUrlBase64(queryParams.releaseId), 10)
                this.titleService.setTitle(releaseContext.project.name)

                // If we have a release ID, we populate the current release from the list of loaded releases
                this.store.dispatch(new LoadReleaseDataAction({ releaseContextId: releaseContext.id, releaseId, updateCurrentRelease: !!queryParams.releaseId }))
            })

        queryParams$.subscribe((params) => {
            this.queryParams = params
        })
    }

    private getQueryParams() {
        return this.store.pipe(select(selectQueryParams), filter((params) => !!params))
    }

    private getParams() {
        return this.store.pipe(select(selectRouteParams), filter((params) => !!params))
    }

    private setReferenceRelease() {
        this.currentRelease$.pipe(skipWhile((currentRelease: Release) => !currentRelease), untilDestroyed(this))
            .subscribe((currentRelease: Release) => {
                if (!!currentRelease?.defaultReferenceRelease) {
                    this.store.dispatch(new SetReferenceReleaseAction(currentRelease.defaultReferenceRelease))
                }

            })
    }

    ngAfterViewInit() {
        // We need to make sure that the progress bar is rendered before setting the loading state

        const loadingContent$ = this.store.pipe(select(isLoadingSelector))
        const loadingPdf$ = this.store.pipe(select(isLoadingPdf))

        combineLatest([loadingContent$, loadingPdf$])
            .pipe(
                untilDestroyed(this)
            ).subscribe(([loadingState, loadingPdf]) => {
                if (loadingState || loadingPdf) {
                    this.progressBar.set(0)
                    this.progressBar.start()
                } else {
                    this.progressBar.complete()
                }
            })
    }

    public decodeUrlBase64(value: string): string {
        return decodeURIComponent(atob(value))
    }
}
