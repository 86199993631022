import { Component, Input, OnInit } from '@angular/core'
import { MaterialCategory } from '../../../entities/material-category.model'
import { isUndefined } from 'lodash'
import { ContentContainer } from '../../../entities/content-container.model'

@Component({
  selector: 'material-category-list',
  templateUrl: './material-category-list.component.html',
  styleUrls: ['./material-category-list.component.scss']
})
export class MaterialCategoryListComponent implements OnInit {

  @Input()
  materialCategories: MaterialCategory[]

  @Input()
  queryParams = {}

  @Input()
  entity: ContentContainer

  @Input()
  language: string

  @Input()
  bookElement: string

  translations: Map<number, string> = new Map()

  constructor() { }

  ngOnInit(): void {

    this.materialCategories = this.removeDuplicates(this.materialCategories)

    this.materialCategories.forEach((mc) => {
      const tr = mc.translations.find((translation) => {
        return translation.locale.substring(0, translation.locale.indexOf('-')).toUpperCase() === this.language.toUpperCase()
      })
      this.translations.set(mc.id, (isUndefined(tr) || tr.text === null) ? mc.name : tr.text)
    })
  }

  private removeDuplicates(materialCategories: MaterialCategory[]): MaterialCategory[] {
    return materialCategories.filter((materialCategory, index, self) =>
      self.findIndex((mc) =>
        mc.originalId === materialCategory.originalId && mc.implementationType === materialCategory.implementationType) === index)
  }
}
