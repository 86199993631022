import { Title } from '@angular/platform-browser'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { StateStorageService } from '../auth/state-storage.service'
import { ProfileEnvironmentEnum } from '../../layouts/profiles/profile.service'

@Injectable()
export class TitleService {

    constructor(private title: Title, private translateService: TranslateService, private readonly stateStorageService: StateStorageService) {
    }

    /**
     * Sets title to the document. If null is passed it would return 'Missing Translation'.
     * If there is any makrdown it will be cleaned up.
     * @param newTitle - title of the document.
     */
    public setTitle(newTitle: string) {
        if (!newTitle) {
            this.translateService.get('global.navigation.headingMissing').subscribe((translatedTitle) => {
                this.buildTitle(translatedTitle)
            })
        } else {
            const cleanedTitle = this.clearMarkdown(newTitle)
            this.buildTitle(cleanedTitle)
        }
    }

    /**
     * Sets title for the search page.
     */
    public setSearchPageTitle(query: string) {
        this.translateService.get('kstandards.search.title').subscribe((translatedTitle) => {
            // Query - Suchergebnisse.
            const newTitle = `${query} - ${translatedTitle}`
            this.buildTitle(newTitle)
        })
    }

    /**
     * Sets title for the home page.
     * This is required because during the transition between the book and the home page the title is not changed.
     */
    public setHomepageTitle() {
        this.translateService.get(this.isLidl ? 'kstandards.home.pageTitleLidl' : 'kstandards.home.pageTitle').subscribe((translatedTitle) => {
            this.buildTitle(translatedTitle)
        })
    }

    private clearMarkdown(newTitle: string): string {
        if (!newTitle) {
            return null
        }

        return newTitle
            .replace(/{\+/g, '')
            .replace(/\+}/g, '')
    }

    private buildTitle(newTitle) {
        this.title.setTitle(`${newTitle} | ${this.isLidl ? 'Lidl' : 'Kaufland'} Baustandards`)
    }

    get isLidl(): boolean {
        return this.stateStorageService.getProfileEnvironment() === ProfileEnvironmentEnum.Lidl
    }
}
