import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'dropdown-material-category',
  templateUrl: './dropdown-material-category.component.html',
  styleUrls: ['./dropdown-material-category.component.scss']
})
export class DropdownMaterialCategoryComponent implements OnInit {

    @Input()
    public items = []

    @Input()
    public materialCategoryOriginalIdSelected

    public materialCategorySelected

    @Output()
    itemSelectedEvent: EventEmitter<any> = new EventEmitter<any>()

    constructor() { }

    ngOnInit(): void {
        this.materialCategorySelected = this.materialCategoryOriginalIdSelected ?
            this.items.filter((item) => item.originalId === +this.materialCategoryOriginalIdSelected)[0] : null
    }

    itemSelected(item?): void {
        this.materialCategoryOriginalIdSelected = item ? item.originalId : null
        this.ngOnInit()
        this.itemSelectedEvent.emit(this.materialCategoryOriginalIdSelected)
    }

}
