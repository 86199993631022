export class SearchFile {
    constructor(
        public checksum?: string,
        public fileName?: string,
        public url?: string,
        public size?: number,
        public type?: string
    ) { }
}

export class SearchMaterialCategory {
    constructor(
        public originalId?: number,
        public icon?: SearchFile
    ) {
    }
}

export class SearchRelease {
    constructor(
        public projectFragment: string,
        public languageFragment: string,
        public construction: boolean,
        public releaseId: number
    ) {

    }
}

export class SearchResult {

    constructor(
        public heading?: string,
        public snippet?: string,
        public label?: string,
        public type?: string,
        public originalId?: number,
        public thumbUrl?: string,
        public file?: SearchFile,
        public release?: SearchRelease,
        public materialCategories?: SearchMaterialCategory[]) {

    }

    public get url(): string[] {
        switch (this.type) {
            case 'Material':
                return ['material', '' + this.originalId]
            case 'Craft':
                return ['craft', '' + this.originalId]
            case 'MaterialGroup':
                return ['material-group', '' + this.originalId]
            case 'Room':
                return ['room', '' + this.originalId]
            case 'Manufacturer':
                return ['manufacturer', '' + this.originalId]
            case 'Changelog':
                return ['changelog']
        }

    }
}
