import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Change, RevitModel } from '../../entities'
import { SERVER_API_URL } from '../../../app/app.constants'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})
export class RevitService {

    constructor(private readonly httpClient: HttpClient) {
    }

    getChangelog(releaseId: number) {
        return this.httpClient.get<Change[]>(`${SERVER_API_URL}/api/releases/${releaseId}/changes`, { observe: 'body' })
    }

    findMaterial(buildingType: number, language: string, country: string, label: string, constructionType: string): Observable<RevitModel> {
        const params = new HttpParams().set('buildingType', buildingType.toString(10))
            .set('constructionType', constructionType)
            .set('language', language)
            .set('country', country)
            .set('label', label)

        return this.httpClient.get<RevitModel>(`${SERVER_API_URL}/api/materials`, {params})
            .pipe(map((material) => Object.assign(new RevitModel(), material)))
    }

    findRoom(buildingType: number, language: string, country: string, number: string, constructionType: string): Observable<RevitModel> {
        const params = new HttpParams().set('buildingType', buildingType.toString(10))
            .set('constructionType', constructionType)
            .set('language', language)
            .set('country', country)
            .set('number', number)

        return this.httpClient.get<RevitModel>(`${SERVER_API_URL}/api/rooms`, {params})
            .pipe(map((room) => Object.assign(new RevitModel(), room)))
    }

    findRelease(buildingType: number, language: string, country: string, constructionType: string): Observable<RevitModel> {
        const params = new HttpParams().set('buildingType', buildingType.toString(10))
            .set('constructionType', constructionType)
            .set('language', language)
            .set('country', country)

        return this.httpClient.get<RevitModel>(`${SERVER_API_URL}/api/release-contexts/revit`, {params})
            .pipe(map((room) => Object.assign(new RevitModel(), room)))
    }

}
