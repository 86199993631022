import { Pipe, PipeTransform } from '@angular/core'
import * as marked from 'marked'
import { MarkedOptions } from 'marked'

/**
 * Renders the preview of the textContent of a Content entity.
 * It uses a specifically configured instance of marked.js.
 */
@Pipe({
  name: 'preview'
})
export class PreviewPipe implements PipeTransform {

    transform(text: string, levelOffset = 0): string {

        if (!text) {
            return ''
        }

        const renderer = new marked.Renderer()

        // Add Bootstrap table classes for better styling
        renderer.table = (header, body) => {
            return '<table class="table table-responsive">\n'
            + '<thead>\n'
            + header
            + '</thead>\n'
            + '<tbody>\n'
            + body
            + '</tbody>\n'
            + '</table>\n'
        }

        // Don't add ids to headings here
        renderer.heading = (content, level, raw) => {
            level += levelOffset
            return `<h${level}>${content}</h${level}>\n`
        }

        const markdownOptions: MarkedOptions = {
            smartLists: true,
            gfm: true,
            renderer
        }

        return marked.parse(text, markdownOptions)
    }

}
