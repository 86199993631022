import { BaseEntity } from '../shared/model/base-entity'
import { File } from './file.model'
import { Product } from './product.model'

export class Manufacturer implements BaseEntity {
    constructor(
        public id?: number,
        public originalId?: number,
        public name?: string,
        public description?: string,
        public files?: File[],
        public products?: Product[]
    ) {
    }
}
