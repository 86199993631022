import { Routes } from '@angular/router'

import { UserRouteAccessService } from '../../shared'
import { Roles } from '../../shared/auth/roles'
import { ChangelogComponent } from './changelog.component'

export const changelogRoutes: Routes = [
    {
        path: 'changelog',
        component: ChangelogComponent,
        data: {
            authorities: Roles.VIEWER
        },
        canActivate: [UserRouteAccessService]
    }
]
