import { HttpClientModule } from '@angular/common/http'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'

import { JhipsterSharedModule } from '../../shared'
import { ProductService } from './product.service'

@NgModule({
    imports: [
        JhipsterSharedModule,
        HttpClientModule,
    ],
    providers: [
        ProductService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProductModule { }
