import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { NullValidationHandler, OAuthEvent, OAuthService } from 'angular-oauth2-oidc'
import { JhiAlert, JhiAlertService, JhiLanguageService } from 'ng-jhipster'
import { Observable, ReplaySubject } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { ProfileEnvironmentEnum } from '../../layouts/profiles/profile.service'
import { OAuth2Config } from '../config/oauth2/oauth2.config'
import { LanguageChoiceService } from '../language/language-choice.service'
import { HostCompanyIdEnum, RoleIdEnum } from './roles'
import { StateStorageService } from './state-storage.service'
import { TokenParser } from './token.parser'

@Injectable()
export class OAuth2Service {

    private authenticationState$ = new ReplaySubject<boolean>(1)
    private authState = false
    private account$ = new ReplaySubject<any>(1)
    private account
    private alerts: JhiAlert[] = []

    constructor(private readonly oauthConfig: OAuth2Config,
        private readonly oauthService: OAuthService,
        private readonly stateStorageService: StateStorageService,
        private readonly languageService: JhiLanguageService,
        private readonly languageChoiceService: LanguageChoiceService,
        private readonly jhiAlertService: JhiAlertService,
        private readonly router: Router) {
    }

    /**
     *
     * Configure the OAuth2 API.
     */
    public initOAuth() {
        this.oauthConfig.loadConfigurationFile().then((config) => {
            // We ignore the id_token validation because it is not properly provided
            this.oauthService.tokenValidationHandler = new NullValidationHandler()
            this.oauthService.configure({
                issuer: config['issuer'],
                redirectUri: `${window.location.origin}/`,
                clientId: config['clientId'],
                responseType: 'code',
                scope: config['scope'],
                strictDiscoveryDocumentValidation: config['strictDiscoveryDocumentValidation'],
                // Disable at_hash check for id_token. It is not provided
                disableAtHashCheck: true,
                requestAccessToken: true,
                clearHashAfterLogin: false,
            })

            // Resource server specified by Himli Koray Tekin
            this.oauthService.customQueryParams = {
                'resourceServer': 'IdentityProviderRSUE'
            }

            const customIdParameter = config['customIdParameter']
            if (!!customIdParameter) {
                this.oauthService.customQueryParams['acr_values'] = customIdParameter
            }

            this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
                this.updateState()
            })

            this.oauthService.events.pipe(filter(({ type }) => type === 'logout' || type === 'token_received')).subscribe((event: OAuthEvent) => {
                switch (event.type) {
                    case 'logout':
                        this.account$ = null
                        this.authenticationState$.next(null)
                        break
                    case 'token_received':
                        this.updateState()

                        const preferredLanguage = this.languageChoiceService.getPreferredLanguage()
                        this.languageService.changeLanguage(preferredLanguage)

                        // Emit success alert
                        this.jhiAlertService.success('global.auth.successfulLogin', 'user')

                        // Redirect to the proper route
                        const redirectUrl = this.stateStorageService.getUrl()
                        // Clean up state storage
                        this.stateStorageService.storeUrl(null)
                        if (redirectUrl) {
                            this.router.navigateByUrl(redirectUrl)
                        } else {
                            this.router.navigate(['/'])
                        }
                        break
                }
            })
        })
    }

    private checkMissingRolesAndShowAlert(): void {
        const profileEnvironment: ProfileEnvironmentEnum = this.stateStorageService.getProfileEnvironment()

        const additionalRoles: string[] = this.account['authorities']
            .filter(((authority: string) => authority.includes(RoleIdEnum.Intern) || authority.includes(RoleIdEnum.Extern)))
        const isViewer: boolean = this.account['authorities'].some(((authority: string) => authority.includes(RoleIdEnum.Viewer)))
        const isAdmin: boolean = this.account['authorities'].some(((authority: string) => authority.includes(RoleIdEnum.Admin)))

        let alertMsgId: string

        if (
            (!this.account['authorities'] || this.account['authorities'].length === 0)
            && profileEnvironment === ProfileEnvironmentEnum.Lidl
            && !this.isAlertActive('notAuthorized.anyRoleMissingForLidlUser')
        ) {
            alertMsgId = 'notAuthorized.anyRoleMissingForLidlUser'
        } else if (
            (!this.account['authorities'] || this.account['authorities'].length === 0)
            && profileEnvironment === ProfileEnvironmentEnum.Kaufland
            && !this.isAlertActive('notAuthorized.anyRoleMissingForKauflandUser')
        ) {
            alertMsgId = 'notAuthorized.anyRoleMissingForKauflandUser'
        } else if (
            additionalRoles.length > 0
            && this.account['authorities'].length === additionalRoles.length
            && !this.isAlertActive('notAuthorized.basicRoleMissing')
        ) {
            alertMsgId = 'notAuthorized.basicRoleMissing'
        } else if (
            isViewer
            && !isAdmin
            && additionalRoles.length === 0
            && !this.isAlertActive('notAuthorized.additionalRoleMissing')
        ) {
            alertMsgId = 'notAuthorized.additionalRoleMissing'
        }

        if (!alertMsgId) {
            return
        }
        const alertOptions: JhiAlert = {
            type: 'danger',
            msg: alertMsgId,
            timeout: -1,
            params: { id: alertMsgId }
        }
        this.alerts.push(alertOptions)
        this.jhiAlertService.addAlert(alertOptions, this.alerts)
    }

    private updateState() {
        const isAuthenticated = this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()
        this.authState = isAuthenticated
        this.authenticationState$.next(isAuthenticated)
        if (isAuthenticated) {
            this.account = TokenParser.parse(this.oauthService.getAccessToken())
            this.account$.next(this.account)
            this.checkMissingRolesAndShowAlert()
        } else {
            this.account$.next(null)
        }
    }

    get authenticationState(): Observable<boolean> {
        return this.authenticationState$.asObservable()
    }

    get userIdentity(): Observable<any> {
        return this.account$.asObservable()
    }

    public isAuthenticated() {
        return this.authState
    }

    /**
     *
     * Checks if there is a specific role.
     * @param {string[]} authorities - roles to check.
     * @returns {Promise<boolean>} - promise of the authorization result.
     */
    public hasAnyAuthority(authorities: string[]): Observable<boolean> {
        return this.account$.pipe(map((account) => {
            return this.hasAnyAuthorityDirect(account, authorities)
        }))
    }

    /**
 *
 * Check if the user has the required roles.
 * @param {string[]} authorities - the authorities provided as data for the route.
 * @returns {boolean} - does the user have any of them.
 */
    private hasAnyAuthorityDirect(account: any, authorities: string[]): boolean {
        if (!account) {
            return false
        }

        for (let i = 0; i < authorities.length; i++) {
            if (account.authorities.indexOf(authorities[i]) !== -1) {
                return true
            }
        }

        return false
    }

    /**
     *
     * Perform login with the implicit flow.
     */
    public login(url?: string) {
        if (url) {
            this.stateStorageService.storeUrl(url)
        } else {

        }

        this.oauthService.initCodeFlow()
    }

    /**
     *
     * Logout without redirecting which causes a page refresh.
     */
    public logout() {
        this.stateStorageService.storeUrl(null)
        this.oauthService.logOut()
    }

    public getHostCompanyIdFromRole(): HostCompanyIdEnum {
        const kStandards: string = !!this.account && this.account.authorities.find((authority: string) => authority.startsWith(HostCompanyIdEnum.KStandards))
        const lStandards: string = !!this.account && this.account.authorities.find((authority: string) => authority.startsWith(HostCompanyIdEnum.LStandards))

        if (!!kStandards && !lStandards) {
            return HostCompanyIdEnum.KStandards
        } else if (!kStandards && !!lStandards) {
            return HostCompanyIdEnum.LStandards
        } else {
            return undefined
        }
    }

    private isAlertActive(id: string): boolean {
        return this.alerts.map((alert: JhiAlert) => alert?.params?.id).includes(id)
    }
}
