import { BaseEntity } from '../shared/model/base-entity'
import { File } from './file.model'
import { Translation } from './translation.model'

export class MaterialCategory implements BaseEntity {
    constructor(
        public id?: number,
        public originalId?: number,
        public name?: string,
        public description?: string,
        public implementationType?: ImplementationType,
        public icon?: File,
        public translations?: Translation[],
    ) {
    }
}

export enum ImplementationType {
    IMPLEMENTATION_TYPE_1,
    IMPLEMENTATION_TYPE_2,
    IMPLEMENTATION_TYPE_3,
    IMPLEMENTATION_TYPE_4,
    IMPLEMENTATION_TYPE_5,
    IMPLEMENTATION_TYPE_6,
    IMPLEMENTATION_TYPE_7,
    IMPLEMENTATION_TYPE_8,
    IMPLEMENTATION_TYPE_9,
    IMPLEMENTATION_TYPE_10
}
