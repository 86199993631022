import { Component, OnInit, OnDestroy } from '@angular/core'
import { ChangelogService } from './changelog.service'
import { BookState } from '../redux/book.state'
import { Store, select } from '@ngrx/store'
import { getCurrentRelease, selectQueryParams } from '../redux/state'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { switchMap, filter } from 'rxjs/operators'
import { Release } from '../../entities/release.model'
import { Change } from '../../entities'
import { StopLoadingAction, StartLoadingAction } from '../redux/actions/loading.action'

@UntilDestroy()
@Component({
    selector: 'kst-changelog',
    templateUrl: 'changelog.component.html',
    styleUrls: ['changelog.component.scss']
})
export class ChangelogComponent implements OnInit, OnDestroy {

    public changes: Change[]

    public queryParams: any
    public hasLoaded = false

    constructor(
        private changelogService: ChangelogService,
        private readonly store: Store<BookState>
    ) { }

    ngOnInit() {

        this.store.dispatch(new StopLoadingAction())
        this.store.dispatch(new StartLoadingAction())

        this.store.pipe(
            select(getCurrentRelease),
            filter((currentRelease) => !!currentRelease),
            switchMap((currentRelease: Release) => this.changelogService.getChangelog(currentRelease.id)),
            untilDestroyed(this)
        ).subscribe((changes) => {
            this.changes = changes
            this.hasLoaded = true
            this.store.dispatch(new StopLoadingAction())
        })

        this.store
            .pipe(
                select(selectQueryParams),
                untilDestroyed(this)
            )
            .subscribe((params) => {
                this.queryParams = params
            })

    }

    ngOnDestroy() {

    }

    getFlag(code: string): string {
        code = code.toLowerCase()
        switch (code) {
            case 'int':
                code = 'eu'
                break
            case 'nie':
                code = 'gb-nir'
                break
            case 'uk':
                code = 'gb'
                break
            case 'en':
            case 'eng':
                code = 'gb-eng'
                break
            case 'sct':
                code = 'gb-sct'
                break
            case 'wls':
                code = 'gb-wls'
                break
            default:
                break
        }
        return code
    }

}
