import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { SERVER_API_URL } from '../../app.constants'
import { Change } from '../../entities'

@Injectable({
    providedIn: 'root'
})
export class ChangelogService {

    constructor(private readonly httpClient: HttpClient) {
    }

    getChangelog(releaseId: number) {
        return this.httpClient.get<Change[]>(`${SERVER_API_URL}/api/releases/${releaseId}/changes`, { observe: 'body' })
    }

}
