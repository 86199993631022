import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgbAccordionModule, NgbAlertModule, NgbCollapseModule, NgbDropdownModule, NgbPaginationModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap'
import { TranslateModule } from '@ngx-translate/core'
import { NgJhipsterModule } from 'ng-jhipster'

@NgModule({
    imports: [
        NgbDropdownModule,
        NgbAccordionModule,
        NgbPaginationModule,
        NgbCollapseModule,
        NgbPopoverModule,
        NgbAlertModule,
        NgJhipsterModule.forRoot({
            // set below to true to make alerts look like toast
            alertAsToast: false,
            i18nEnabled: true,
            // Preferred language when the app is open is set up in JhiMainComponent
            defaultI18nLang: 'de'
        }),
        FontAwesomeModule,
    ],
    exports: [
        FormsModule,
        CommonModule,
        NgJhipsterModule,
        TranslateModule,
        NgbDropdownModule,
        NgbAccordionModule,
        NgbPaginationModule,
        NgbCollapseModule,
        NgbPopoverModule,
        NgbAlertModule,
        FontAwesomeModule,
    ]
})
export class JhipsterSharedLibsModule {
}
