import { Room } from './room.model'

export class RoomGroup {
  constructor(public id?: number,
              public name?: string,
              public rooms?: Room[],
              public orderId?: number,
              public originalId?: number,
              public isCollapsed?: boolean) {

    if (!this.rooms) {
        this.rooms = []
    }

    this.isCollapsed = true

  }
}
