import { Routes } from '@angular/router'

import { UserRouteAccessService } from '../../shared'
import { MaterialDetailComponent } from './material-detail.component'
import { Roles } from '../../shared/auth/roles'

export const materialRoutes: Routes = [
    {
        path: 'material/:id',
        component: MaterialDetailComponent,
        data: {
            authorities: Roles.VIEWER
        },
        canActivate: [UserRouteAccessService]
    }
]
