import { Pipe, PipeTransform } from '@angular/core'
import { MaterialCategory } from '../../entities/material-category.model'

const MATERIAL_CATEGORY_ICON_REGEX = /<\[?MATERIAL\\*_CATEGORY\\*_ICON\+?\}?_\{?\+?(\d+)\]?>/g
const NUMBER_REGEX = /\d+/g

@Pipe({
  name: 'previewMaterialCategoryIcon'
})
export class PreviewMaterialCategoryIconPipe implements PipeTransform {

  transform(text: string, materialCategories: MaterialCategory[]): string {
      if (!text) {
          return ''
      }

      if (!!materialCategories?.length && this.checkRegex(MATERIAL_CATEGORY_ICON_REGEX, text)) {
          const urlMap: Map<string, string> = new Map(materialCategories.map(
              (mc) => [mc.originalId.toString(), mc.icon?.url])
          )
          const matchContent = this.getRegexMatch(MATERIAL_CATEGORY_ICON_REGEX, text)
          for (let i = 0; i < matchContent.length; i++) {
              const textToReplace: string = matchContent[i]
              const number: string = this.getRegexMatch(NUMBER_REGEX, textToReplace)[0]
              const value: string = urlMap.get(number)
              text = this.replaceText(text, textToReplace, value)
          }
      }

      return text
  }

    private replaceText(text: string, textToReplace: string, value: string): string {
        return text.split(textToReplace).join('<img class="icon" src="' + value + '">')
    }

    private checkRegex(regex: RegExp, content: string): boolean {
        regex.lastIndex = 0
        return regex.test(content)
    }

    private getRegexMatch(regex: RegExp, content: string): RegExpMatchArray {
        regex.lastIndex = 0
        return content.match(regex)
    }

}
