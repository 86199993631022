import { ContentContainer } from './content-container.model'
import { AttachmentCategory } from './attachment-category.model'
import { FileContext } from './file-context.model'
import { MaterialRoomRelation } from './material-room-relation.model'
import { Product } from './product.model'
import { MaterialGroup } from './material-group.model'
import { Craft } from './craft.model'
import { MaterialCategory } from './material-category.model'

export class Material implements ContentContainer {
    constructor(public id?: number,
                public originalId?: number,
                public orderId?: number,
                public heading?: string,
                public textContent?: string,
                public linkedAttachmentCategories?: AttachmentCategory[],
                public fileContexts?: FileContext[],
                public label?: string,
                public annotation?: string,
                public materialRoomRelations?: MaterialRoomRelation[],
                public parentContents?: string,
                public parentGroup?: MaterialGroup,
                public parentCraft?: Craft,
                public products?: Product[],
                public materialCategories?: MaterialCategory[],
                public isNew?: boolean) {
        if (!this.fileContexts) {
            this.fileContexts = []
        }
        if (!this.linkedAttachmentCategories) {
            this.linkedAttachmentCategories = []
        }
        if (!this.materialRoomRelations) {
            this.materialRoomRelations = []
        }
        if (!this.products) {
            this.products = []
        }
        if (!this.materialCategories) {
            this.materialCategories = []
        }
    }
    get images() {
        return this.fileContexts ? this.fileContexts.filter((fileContext) => fileContext.file.fileType.startsWith('image/')) : []
    }

    get pdfs() {
        return this.fileContexts ? this.fileContexts.filter((fileContext) => fileContext.file.fileType.startsWith('application/pdf')) : []
    }

    get files() {
        return this.fileContexts
            ? this.fileContexts.filter((fileContext) => !(fileContext.file.fileType.startsWith('application/pdf') || fileContext.file.fileType.startsWith('image/')))
            : []
    }

}
