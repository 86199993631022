import { Action } from '@ngrx/store'
import { Room, RoomGroup } from '../../../entities'
import { CraftTableItem } from '../../navigation/table-of-contents/craft-table-item.model'
import { AttachmentCategoryTableItem } from '../../navigation/table-of-contents/attachment-category-table-item.model'

export enum BookNavigationActionType {
    SELECT_ROOMBOOK = '[Book navigation] Select roombook',
    SELECT_TABLE_OF_CONTENTS = '[Book navigation] Select table of contents',
    TOGGLE_BOOK_NAVIGATION = '[Book navigation] Toggle book navigation',
    HIDE_BOOK_NAVIGATION = '[Book navigation] Hide book navigation',
    SHOW_BOOK_NAVIGATION = '[Book navigation] Show book navigation',
    SET_ROOMBOOK_DATA = '[Book navigation] Set roombook data',
    SET_TABLE_OF_CONTENTS_DATA = '[Book navigation] Set table of contents data',
    SET_ATTACHMENT_CATEGORY_DATA = '[Book navigation] Set attachment category data',
    START_LOADING = '[Book navigation] Start loading',
    STOP_LOADING = '[Book navigation] Stop loading'
}

export class BookNavigationAction implements Action {
    readonly type: BookNavigationActionType

    constructor(public payload?: any) { }
}

export class SelectRoombookAction implements BookNavigationAction {
    readonly type  = BookNavigationActionType.SELECT_ROOMBOOK

    constructor(public payload?: Room) { }
}

export class SelectTableOfContentsAction implements BookNavigationAction {
    readonly type = BookNavigationActionType.SELECT_TABLE_OF_CONTENTS

}

export class ToggleBookNavigationAction implements BookNavigationAction {
    readonly type = BookNavigationActionType.TOGGLE_BOOK_NAVIGATION
}

export class HideBookNavigationAction implements BookNavigationAction {
    readonly type = BookNavigationActionType.HIDE_BOOK_NAVIGATION
}

export class ShowBookNavigationAction implements BookNavigationAction {
    readonly type = BookNavigationActionType.SHOW_BOOK_NAVIGATION
}

export class SetRoomBookDataAction implements BookNavigationAction {
    readonly type = BookNavigationActionType.SET_ROOMBOOK_DATA

    constructor(public payload?: RoomGroup[]) { }
}

export class SetTableOfContentsDataAction implements BookNavigationAction {
    readonly type = BookNavigationActionType.SET_TABLE_OF_CONTENTS_DATA

    constructor(public payload?: CraftTableItem[]) { }
}

export class SetAttachmentCategoryDataAction implements BookNavigationAction {
    readonly type = BookNavigationActionType.SET_ATTACHMENT_CATEGORY_DATA

    constructor(public payload?: AttachmentCategoryTableItem[]) { }
}
