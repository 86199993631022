import { Release } from '../../../entities/release.model'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { FileContext } from '../../../entities/file-context.model'
import { File } from '../../../entities/file.model'

export interface ReleaseState {
    releases: {
        loaded: boolean,
        data: Release[]
    }
    currentRelease: Release,
    selectedReferenceRelease: {
        loaded: boolean,
        release: Release
    },
    showSidebar: boolean,
    gallery: FileContext[]
    pdfs: {
        loaded: boolean
        data: {
            [checksum: string]: File
        }

    }
}

export const getReleaseState = createFeatureSelector<ReleaseState>('releases')

export const getCurrentRelease = createSelector(getReleaseState, (state: ReleaseState) => state.currentRelease)
export const getReferenceRelease = createSelector(getReleaseState, (state: ReleaseState) => state.selectedReferenceRelease)
export const shouldShowSidebar = createSelector(getReleaseState, (state: ReleaseState) => state.showSidebar)
export const getAllReleasesForContext = createSelector(getReleaseState, (state: ReleaseState) => state.releases)
export const selectGallery = createSelector(getReleaseState, (state: ReleaseState) => state.gallery)
export const selectPdfs = createSelector(getReleaseState, (state: ReleaseState) => state.pdfs)
