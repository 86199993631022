import { Effect, ofType, Actions } from '@ngrx/effects'
import { ReleaseActionType, LoadReleaseDataAction } from '../actions/release.action'
import { switchMap, map, catchError, filter } from 'rxjs/operators'
import { RoomGroup } from '../../../entities'
import { SetRoomBookDataAction,
    SetTableOfContentsDataAction,
    SetAttachmentCategoryDataAction,
    BookNavigationActionType,
    SelectTableOfContentsAction
} from '../actions/book-navigation.action'
import { EMPTY } from 'rxjs'
import { CraftTableItem } from '../../navigation/table-of-contents/craft-table-item.model'
import { RoomBookService } from '../../navigation'
import { TableOfContentsService } from '../../navigation/table-of-contents/table-of-contents.service'
import { Injectable } from '@angular/core'
import { AttachmentCategoryTableItem } from '../../navigation/table-of-contents/attachment-category-table-item.model'

@Injectable({
    providedIn: 'root'
})
export class BookNavigationEffects {

    @Effect()
    loadRoombook$ = this.actions$
        .pipe(
            ofType(ReleaseActionType.LOAD_RELEASE_DATA),
            switchMap((action: LoadReleaseDataAction) => {
                return this.roombookService
                    .getRoombook(action.payload.releaseId)
                    .pipe(
                        map((roombook: RoomGroup[]) => new SetRoomBookDataAction(roombook)),
                        catchError(() => EMPTY))
            })
        )

    @Effect()
    loadTableOfContents$ = this.actions$
        .pipe(
            ofType(ReleaseActionType.LOAD_RELEASE_DATA),
            switchMap((action: LoadReleaseDataAction) => {
                return this.tableOfContentsService
                    .queryCrafts(action.payload.releaseId)
                    .pipe(
                        map((tableOfContents: CraftTableItem[]) => new SetTableOfContentsDataAction(tableOfContents)),
                        catchError(() => EMPTY))
            })
        )

    @Effect()
    loadAttachmentCategories$ = this.actions$
        .pipe(
            ofType(ReleaseActionType.LOAD_RELEASE_DATA),
            switchMap((action: LoadReleaseDataAction) => {
                return this.tableOfContentsService
                    .queryAttachmentCategories(action.payload.releaseId)
                    .pipe(
                        map((attachmentCategories: AttachmentCategoryTableItem[]) => new SetAttachmentCategoryDataAction(attachmentCategories)),
                        catchError(() => EMPTY))
            })
        )

    // This effect automatically selects the table of contents if the release does not have roombook data
    @Effect()
    switchInitialMode$ = this.actions$.pipe(
        ofType(BookNavigationActionType.SET_ROOMBOOK_DATA),
        filter((roombookAction: SetRoomBookDataAction) => {
            return roombookAction.payload.length === 0
        }),
        map(() => new SelectTableOfContentsAction())
    )

    constructor(
        private actions$: Actions,
        private tableOfContentsService: TableOfContentsService,
        private roombookService: RoomBookService
    ) { }

}
