import { Component, OnInit } from '@angular/core'
import { map, skipWhile, take } from 'rxjs/operators'
import { ProfileEnvironmentEnum } from '../layouts'
import { OAuth2Service } from '../shared/auth/oauth2.service'
import { RoleIdEnum } from '../shared/auth/roles'
import { StateStorageService } from '../shared/auth/state-storage.service'
import { TokenParser } from '../shared/auth/token.parser'

@Component({
    templateUrl: './not-authorized.component.html'
})
export class NotAuthorizedComponent implements OnInit {
    authToken: string
    isBasicRoleMissing = false
    isAdditionalRoleMissing = false
    isAnyRoleMissingForLidlUser = false
    isAnyRoleMissingForKauflandUser = false

    constructor(private readonly oAuth2Service: OAuth2Service, private readonly stateStorageService: StateStorageService) { }

    ngOnInit(): void {
        this.authToken = TokenParser.decodeToken(localStorage.getItem('access_token'))

        const profileEnvironment: ProfileEnvironmentEnum = this.stateStorageService.getProfileEnvironment()

        this.oAuth2Service.userIdentity.pipe(
            take(1),
            skipWhile((identity: any) => !identity['authorities']),
            map((identity: any) => identity['authorities'])
        ).subscribe((authorities: string[]) => {

            const additionalRoles: string[] = authorities
                .filter(((authority: string) => authority.includes(RoleIdEnum.Intern) || authority.includes(RoleIdEnum.Extern)))
            const isViewer: boolean = authorities.some(((authority: string) => authority.includes(RoleIdEnum.Viewer)))
            const isAdmin: boolean = authorities.some(((authority: string) => authority.includes(RoleIdEnum.Admin)))

            if ((!authorities || authorities.length === 0) && profileEnvironment === ProfileEnvironmentEnum.Lidl) {
                this.isAnyRoleMissingForLidlUser = true
            } else if ((!authorities || authorities.length === 0) && profileEnvironment === ProfileEnvironmentEnum.Kaufland) {
                this.isAnyRoleMissingForKauflandUser = true
            } else if (additionalRoles.length > 0 && authorities.length === additionalRoles.length) {
                this.isBasicRoleMissing = true
            } else if (isViewer && !isAdmin && additionalRoles.length === 0) {
                this.isAdditionalRoleMissing = true
            }
        })
    }
}
