import { Routes } from '@angular/router'
import { NotAuthorizedComponent } from './not-authorized.component'
import { UserRouteAccessService } from '../shared/auth/user-route-access-service'

const notAuthorizedPageTitle = 'notAuthorized.title'

export const notAuthorizedRoute: Routes = [
    {
        path: 'errors/not-authorized',
        component: NotAuthorizedComponent,
        data: {
            authorities: [],
            pageTitle: notAuthorizedPageTitle
        },
        canActivate: [UserRouteAccessService]
    }
]
