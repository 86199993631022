
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'

import { ReferenceSelectComponent } from './reference-select.component'
import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { JhipsterSharedModule } from '../../shared'

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        JhipsterSharedModule,
    ],
    declarations: [
        ReferenceSelectComponent
    ],
    entryComponents: [
        ReferenceSelectComponent
    ],
    exports: [
        ReferenceSelectComponent,
    ],
    providers: [
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ReferenceSelectModule {}
