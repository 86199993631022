import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core'

@Component({
    /* tslint:disable */
    selector: 'icon',
    /* tslint:enable */
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    template: `<svg class="icon" [ngClass]="cssClasses" xmlns="http://www.w3.org/2000/svg">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" [attr.xlink:href]="'#sprite-' + shape"></use>
               </svg>`
})
export class IconComponent {

    @Input()
    shape: string

    @Input()
    cssClasses = ''
}
