import { Component, Input, OnInit } from '@angular/core'
import { MaterialCategory } from '../../../entities/material-category.model'
import { isUndefined } from 'lodash'
import { ContentContainer } from '../../../entities/content-container.model'

@Component({
  selector: 'material-category-list-item',
  templateUrl: './material-category-list-item.component.html',
  styleUrls: ['./material-category-list-item.component.scss']
})
export class MaterialCategoryListItemComponent implements OnInit {

  @Input()
  materialCategory: MaterialCategory

  @Input()
  queryParams = {}

  @Input()
  entities: ContentContainer[]

  @Input()
  language: string

  @Input()
  bookElement: string

  translations: Map<number, string> = new Map()

  constructor() { }

  ngOnInit(): void {
      const tr = this.materialCategory.translations.find((translation) => {
        return translation.locale.substring(0, translation.locale.indexOf('-')).toUpperCase() === this.language.toUpperCase()
      })
      this.translations.set(this.materialCategory.id, (isUndefined(tr) || tr.text === null) ? this.materialCategory.name : tr.text)
  }
}
