import { LanguageState } from '../state/language.state'
import { LanguageAction, LanguageActionType, SetLanguagePayload } from '../actions/language.action'

const initialState: LanguageState = {
    selectedLanguage: null
}

export function languageReducer(state = initialState, action: LanguageAction) {
    switch (action.type) {
        case LanguageActionType.CHANGE:
            const payload = action.payload as SetLanguagePayload
            const selectedLanguage = payload.newLanguage
            return {...state, selectedLanguage}
        default:
            return state
    }
}
