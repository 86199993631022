import { Component, OnInit } from '@angular/core'
import { map, filter } from 'rxjs/operators'
import { CraftTableItem } from './craft-table-item.model'
import { ReplaySubject, combineLatest } from 'rxjs'
import { MaterialGroupTableItem } from './material-group-table-item.model'
import { AttachmentCategoryHolder } from './attachment-category-holder.model'
import { AttachmentCategoryTableItem } from './attachment-category-table-item.model'
import { BookState } from '../../redux/book.state'
import { Store, select } from '@ngrx/store'
import { selectQueryParams, getTableOfContentsData, selectCurrentRoute } from '../../redux/state'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'table-of-contents',
    templateUrl: './table-of-contents.component.html',
    styleUrls: ['./table-of-contents.component.scss']
})
export class TableOfContentsComponent implements OnInit {

    public crafts: CraftTableItem[]

    public queryParams = {}

    public attachmentCategoryHolder: AttachmentCategoryHolder

    constructor(private readonly store: Store<BookState>) { }

    ngOnInit(): void {

        const hasLoadedCrafts$ = new ReplaySubject<never>(1)

        this.store.pipe(
            select(getTableOfContentsData),
            filter((data) => data.loaded),
            map((data) => data.data)
        ).subscribe((crafts: CraftTableItem[]) => {
            this.crafts = crafts
            hasLoadedCrafts$.next()
        })

        this.store.pipe(
            select('bookNavigation', 'attachmentCategories'),
            filter((data) => data.loaded),
            map((data) => data.data)
        ).subscribe((attachmentCategories: AttachmentCategoryTableItem[]) => {
            this.attachmentCategoryHolder = new AttachmentCategoryHolder()
            this.attachmentCategoryHolder.attachmentCategories = attachmentCategories
        })

        this.store
            .pipe(
                select(selectQueryParams),
                untilDestroyed(this)
            )
            .subscribe((params) => {
                this.queryParams = params

            })

        const currentRoute$ = this.store.pipe(select(selectCurrentRoute))

        combineLatest([currentRoute$, hasLoadedCrafts$])
        .pipe(
            untilDestroyed(this)
        ).subscribe(([route]) => {
            if (route.routeConfig.path.indexOf('craft') !== -1) {
                const craftId = parseInt(route.params.id, 10)
                this.expandCraft(craftId)
            } else if (route.routeConfig.path.indexOf('material-group') !== -1) {
                const materialGroupId = parseInt(route.params.id, 10)
                this.expandCraftWithMaterialGroupId(materialGroupId)
            }
        })

    }

    toggle(craft: CraftTableItem) {

        for (const currentCraft of this.crafts) {

            if (currentCraft.originalId === craft.originalId) {
                continue
            }

            currentCraft.isCollapsed = true
        }

        craft.isCollapsed = !craft.isCollapsed

    }

    expandCraft(craftId: number) {
        for (const craft of this.crafts) {
            if (craft.originalId === craftId) {
                craft.isCollapsed = false
            } else {
                craft.isCollapsed = true
            }
        }
    }

    expandCraftWithMaterialGroupId(materialGroupId: number) {

        for (const craft of this.crafts) {
            const containsMaterialGroup = craft.materialGroups.filter((materialGroup: MaterialGroupTableItem) => materialGroup.originalId === materialGroupId).length > 0
            // Expand the craft if it contains the material group
            craft.isCollapsed = !containsMaterialGroup
        }

    }

    toggleAttachmentCategories() {
        this.attachmentCategoryHolder.isCollapsed = !this.attachmentCategoryHolder.isCollapsed
    }

}
