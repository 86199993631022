import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable()
export class NotificationConfig {

    private readonly notificationConfigUrl: string = 'api/configuration/notification'

    private notificationConfiguration: any

    constructor(private readonly http: HttpClient) {
    }

    loadConfigurationFile(): Promise<any> {
        return new Promise((resolve, reject) => {
            // If the configuration is once loaded return it immediately.
            if (this.notificationConfiguration) {
                return resolve(this.notificationConfiguration)
            }

            this.http
                .get(this.notificationConfigUrl)
                .subscribe((response) => {
                    this.notificationConfiguration = response
                    resolve(response)
                }, (error) => {
                    reject(error)
                })
        })
    }
}
