import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const APP_ROUTES: Routes = [

]

@NgModule({
    imports: [
        RouterModule.forRoot(APP_ROUTES,
            {
                useHash: false,
                paramsInheritanceStrategy: 'always',
                onSameUrlNavigation: 'reload',
                scrollPositionRestoration: 'enabled',
                enableTracing: false,
                relativeLinkResolution: 'legacy'
            }
        )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {}
