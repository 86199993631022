import { Component, Input, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { LocationStrategy } from '@angular/common'
import { JhiLanguageHelper, Roles } from '../../shared'

import { VERSION, SERVER_API_URL } from '../../app.constants'

import { ProfileService } from '../../layouts'
import { OAuth2Service } from '../auth/oauth2.service'
import { Store } from '@ngrx/store'
import { BookNavigationState } from '../../book/redux/state/book-navigation.state'
import { ToggleBookNavigationAction } from '../../book/redux/actions/book-navigation.action'
import { Subject } from 'rxjs'
import { LanguageState } from '../../book/redux/state/language.state'
import { ChangeLanguageAction } from '../../book/redux/actions/language.action'
import { TranslateService } from '@ngx-translate/core'
import { Title } from '@angular/platform-browser'

@Component({
    selector: 'jhi-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: [
        'navbar.scss'
    ]
})
export class NavbarComponent implements OnInit, OnDestroy {

    private readonly componentDestroyed = new Subject<boolean>()

    @Input()
    showSearch = false

    @Input()
    showBookNavigationToggle = false

    @ViewChild('searchField', { read: ElementRef })
    searchField: ElementRef

    Roles = Roles

    user: any

    inProduction: boolean
    isNavbarCollapsed: boolean
    isSearchVisible = false
    languages: any[] = []
    swaggerEnabled: boolean
    version: string
    backendVersion: string
    environment: string
    isLidl: boolean
    logoUrl: string

    query = ''

    @ViewChild('tabMenu')
    tabMenu

    constructor(private readonly oauth2Service: OAuth2Service,
                private readonly languageHelper: JhiLanguageHelper,
                private readonly languageStore: Store<LanguageState>,
                private readonly profileService: ProfileService,
                private readonly router: Router,
                private locationStrategy: LocationStrategy,
                private readonly route: ActivatedRoute,
                private readonly bookStore: Store<BookNavigationState>,
                private readonly titleService: Title,
                private readonly translate: TranslateService) {
        this.version = VERSION
        this.backendVersion = ''
        this.isNavbarCollapsed = true
        this.logoUrl = SERVER_API_URL + '/api/icon/logo'
    }

    ngOnInit() {

        this.languageHelper.getAll().then((languages) => {
            this.languages = languages
        })

        this.profileService.getProfileInfo().subscribe((profileInfo) => {
            this.inProduction = profileInfo.inProduction
            this.swaggerEnabled = profileInfo.swaggerEnabled
            this.isLidl = profileInfo.lidl
            this.backendVersion = profileInfo.backendVersion
            this.environment = this.determineEnvironment(profileInfo.currentStage)
            this.updateFaviconAndPageTitle()
            this.profileService.storeEnvironment(profileInfo)
        })

        this.oauth2Service.userIdentity.subscribe((userIdentity) => this.user = userIdentity)

    }

    ngOnDestroy() {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
    }

    changeLanguage(languageKey: string) {
        this.languageStore.dispatch(new ChangeLanguageAction({newLanguage: languageKey}))
        if (this.router.url === this.locationStrategy.getBaseHref()) {
            this.updateFaviconAndPageTitle()
        }
    }

    collapseNavbar() {
        this.isNavbarCollapsed = true
    }

    isAuthenticated() {
        return this.oauth2Service.isAuthenticated()
    }

    login() {
        this.oauth2Service.login()
    }

    logout() {
        this.collapseNavbar()
        this.oauth2Service.logout()
    }

    toggleNavbar() {
        this.isNavbarCollapsed = !this.isNavbarCollapsed
    }

    toggleBookNavigation() {
        this.bookStore.dispatch(new ToggleBookNavigationAction())
    }

    toggleSearch() {
        this.isSearchVisible = !this.isSearchVisible

        // Focus the search field if it is visible
        if (this.isSearchVisible) {
            setTimeout(() => {
                if (this.searchField) {
                    this.searchField.nativeElement.focus()
                }
            }, 0)
        }
    }

    private updateFaviconAndPageTitle() {
        if (document.getElementById('favicon')) {
            document.getElementById('favicon').setAttribute('href', `${SERVER_API_URL}/api/icon/favicon`)
        }
        const title = this.isLidl ? 'lidlTitle' : 'title'
        this.translate.get(`global.${title}`).subscribe((res: string) => {
            this.titleService.setTitle(res)
        })
    }

    private determineEnvironment(currentStage: string) {
        if (currentStage.toLocaleLowerCase() === 'kfllocal') {  return 'LOCAL' }
        if (currentStage.toLocaleLowerCase() === 'kfldev') {  return 'DEV' }
        if (currentStage.toLocaleLowerCase() === 'kflqa') {  return 'QA' }
        if (currentStage.toLocaleLowerCase() === 'kflprd') {  return 'PROD' }

        if (currentStage.toLocaleLowerCase() === 'ldllocal') {  return 'LIDL-LOCAL' }
        if (currentStage.toLocaleLowerCase() === 'ldldev') {  return 'LIDL-DEV' }
        if (currentStage.toLocaleLowerCase() === 'ldlqa') {  return 'LIDL-QA' }
        if (currentStage.toLocaleLowerCase() === 'ldlprd') {  return 'LIDL-PROD' }

    }
    search() {
        this.isSearchVisible = false
        this.router.navigate(['search'], {
            relativeTo: this.route,
            queryParamsHandling: 'merge',
            queryParams: {
                q: this.query
            }
        })
    }
}
