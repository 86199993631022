import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { FileContext } from '../../entities/file-context.model'
import { BookState } from '../../book/redux/book.state'
import { Store, select } from '@ngrx/store'
import { selectQueryParams } from '../../book/redux/state'
@Component({
    selector: 'image-manager',
    templateUrl: './image-manager.component.html',
    styleUrls: ['./image-manager.component.scss']
})
export class ImageManagerComponent implements OnInit {

    @Input()
    public fileContexts: FileContext[]

    public selectedFileContext: FileContext

    public queryParams$

    constructor(private sanitizer: DomSanitizer, private store: Store<BookState>) {
    }

    ngOnInit() {

        this.queryParams$ = this.store.pipe(select(selectQueryParams))

    }

    public sanitizeImage(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url)
    }

    public track(fileContext: FileContext) {
        return fileContext.id
    }
}
