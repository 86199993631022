import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { Product } from '../../../entities/product.model'
import { ProfileEnvironmentEnum } from '../../../layouts/profiles/profile.service'
import { StateStorageService } from '../../../shared'
import { ProductUtil, ProductMap } from '../../../shared/util/product.util'

@Component({
    selector: 'product-list',
    templateUrl: 'product-list.component.html',
    styleUrls: ['product-list.component.scss']
})
export class ProductListComponent implements OnChanges {

    @Input()
    products: Product[]

    @Input()
    queryParams = {}

    public productMap: ProductMap
    public relations: string[]

    hintsExpanded = false

    public isLidl: boolean = this.stateStorageService.getProfileEnvironment() === ProfileEnvironmentEnum.Lidl

    constructor(
        private readonly stateStorageService: StateStorageService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.products && changes.products.currentValue !== undefined) {
            const products = changes.products.currentValue
            this.productMap = ProductUtil.groupProductsByRelation(products)
            /**
             * Map#keys() throws ExpressionChangedAfterItHasBeenCheckedError since it returns a new Iterator each time:
             * @see: <a href="https://github.com/angular/angular/issues/17725"> Angular feature request</a>
             */
            this.relations = Array.from(this.productMap.keys())
        }
    }

    track(product: Product) {
        return product.id
    }

    expandHints() {
        this.hintsExpanded = true
    }
}
