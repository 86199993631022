export class AttachmentCategoryTableItem {
    constructor(public originalId?: number,
                public heading?: string) {
    }

    public getUrl(): string {
        return `attachment-category/${this.originalId}`
    }

    get hasEmptyHeading() {
        return !this.heading || this.heading.trim() === ''
    }
}
