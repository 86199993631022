import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { ViewStatistics } from '../entities'
import { createHttpParams } from '../shared'

@Injectable()
export class PageViewsService {

    constructor(private readonly http: HttpClient) {
    }

    query(searchWord: string): Observable<ViewStatistics[]> {
        const params = createHttpParams().set('searchWord', searchWord)
        return this.http.get<ViewStatistics[]>(`/api/page-view`, {params})
    }

    queryUnique(searchWord: string): Observable<ViewStatistics[]> {
        const params = createHttpParams().set('searchWord', searchWord)
        return this.http.get<ViewStatistics[]>(`/api/page-view/unique`, {params})
    }
}
