
import { throwError as observableThrowError, Observable } from 'rxjs'

import { catchError } from 'rxjs/operators'
import { JhiEventManager } from 'ng-jhipster'
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

    constructor(private readonly eventManager: JhiEventManager, private readonly router: Router) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError((error: any) => {
            if (!req.headers.has('X-Ignore-Errors')) {
                if (error instanceof HttpErrorResponse && error.status !== 401) {
                    if (error.status === 404) {
                        this.router.navigateByUrl('/errors/not-found', { skipLocationChange: true })
                    }
                    if (error.status === 403) {
                        this.router.navigateByUrl('/errors/not-authorized', { skipLocationChange: true })
                    }
                    if (this.eventManager !== undefined) {
                        this.eventManager.broadcast({name: 'kstandards.httpError', content: error})
                    }
                }
            }

            return observableThrowError(error)
        }))
    }
}
