import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { SERVER_API_URL } from '../../../app.constants'

@Injectable()
export class OAuth2Config {

    private readonly oauth2ConfigUrl: string = SERVER_API_URL + 'api/oauth2/config'

    private oauth2Configuration: any

    constructor(private readonly http: HttpClient) {
    }

    loadConfigurationFile(): Promise<any> {
        return new Promise((resolve, reject) => {
            // If the configuration is once loaded return it immediately.
            if (this.oauth2Configuration) {
                return resolve(this.oauth2Configuration)
            }

            this.http
                .get(this.oauth2ConfigUrl)
                .subscribe((response) => {
                    this.oauth2Configuration = response
                    resolve(response)
                }, (error) => {
                    reject(error)
                })
        })
    }
}
