import { FileContext } from './file-context.model'
import { AttachmentCategory } from './attachment-category.model'
import { RoomBookColumn } from './room-book-column.model'
import { RoomAttributeModel } from './room-attribute.model'

export class Room {
  constructor(public id?: number,
              public heading?: string,
              public number?: string,
              public originalId?: number,
              public roomHeight?: RoomAttributeModel,
              public roomTemperature?: RoomAttributeModel,
              public illuminance?: RoomAttributeModel,
              public airExchange?: RoomAttributeModel,
              public additionalInformation?: RoomAttributeModel,
              public textContent?: string,
              public linkedAttachmentCategories?: AttachmentCategory[],
              public fileContexts?: FileContext[],
              public roomBookColumns?: RoomBookColumn[]) {
      if (!this.fileContexts) {
          this.fileContexts = []
      }
      if (!this.linkedAttachmentCategories) {
          this.linkedAttachmentCategories = []
      }
      if (!this.roomBookColumns) {
          this.roomBookColumns = []
      }
  }

  get hasEmptyHeading() {
    return !this.heading || this.heading.trim() === ''
}

    get images() {
        return this.fileContexts ? this.fileContexts.filter((fileContext) => fileContext.file.fileType.startsWith('image/')) : []
    }

    get pdfs() {
        return this.fileContexts ? this.fileContexts.filter((fileContext) => fileContext.file.fileType.startsWith('application/pdf')) : []
    }

    get files() {
        return this.fileContexts
            ? this.fileContexts.filter((fileContext) => !(fileContext.file.fileType.startsWith('application/pdf') || fileContext.file.fileType.startsWith('image/')))
            : []
    }
}
