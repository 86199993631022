import { Store, select } from '@ngrx/store'

import { BookState } from '../redux/book.state'

import { getReferenceRelease, getCurrentRelease, selectRouteParams } from '../redux/state'

import { filter, map, distinctUntilChanged } from 'rxjs/operators'

import { combineLatest } from 'rxjs'

import { isEqual } from 'lodash'

/**
 * This function builds the parameters for the request of a content container
 * and makes sure to not emit duplicate requests.
 * @param store The NGRX store
 */
export function contentContainerRequest$(store: Store<BookState>) {
    const selectedReference$ = store.pipe(select(getReferenceRelease), filter((reference) => reference.loaded), map((reference) => reference.release))
    const currentRelease$ = store.pipe(select(getCurrentRelease), filter((currentRelease) => !!currentRelease))
    const params$ = store.pipe(select(selectRouteParams), filter((params) => !!params))

    return combineLatest([params$, currentRelease$, selectedReference$])
        .pipe(
            distinctUntilChanged(([prevParams, prevRelease, prevReference], [currentParams, currentRelease, currentReference]) => {
                return isEqual(prevParams, currentParams) &&
                    isEqual(prevRelease, currentRelease) &&
                    isEqual(prevReference, currentReference)
            })
        )
}
