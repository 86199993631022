import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { Release } from '../../entities/release.model'
import { Store, select } from '@ngrx/store'
import { BookState } from '../redux/book.state'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ShowBookNavigationAction } from '../redux/actions/book-navigation.action'
import { getCurrentRelease, getAllReleasesForContext } from '../redux/state/release.state'
import { selectQueryParams } from '../redux/state'

@UntilDestroy()
@Component({
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {

    private readonly componentDestroyed: Subject<boolean> = new Subject()

    public selectedRelease: Release

    public relatedReleases: Release[]

    public queryParams: any

    constructor(private readonly store: Store<BookState>) {
    }

    ngOnInit() {

        this.store.pipe(
            select(getCurrentRelease),
            untilDestroyed(this)
        ).subscribe((currentRelease) => {
            this.selectedRelease = currentRelease
        })

        this.store.pipe(
            select(getAllReleasesForContext),
            filter((partialReleaseState) => partialReleaseState.loaded),
            map((partialReleaseState) => partialReleaseState.data)
        )
            .pipe(
                untilDestroyed(this)
            ).subscribe((releases: Release[]) => {
                this.relatedReleases = releases
            })

        this.store
            .pipe(
                select(selectQueryParams),
                untilDestroyed(this)
            )
            .subscribe((params) => {
                this.queryParams = params
            })

    }

    ngOnDestroy() {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
    }

    openBookNavigation() {
        this.store.dispatch(new ShowBookNavigationAction())
    }

    goToRelease(releaseId: number): void {
        if (this.selectedRelease.id === releaseId) {
            return
        }
        let currentUrl: string = this.getHref()
        if (currentUrl.includes('releaseId=')) {
            currentUrl = currentUrl.replace('releaseId=' + this.encodeUrlBase64(this.selectedRelease.id.toString()), '')
        } else {
            currentUrl = currentUrl + '?'
        }

        this.setHref(currentUrl + 'releaseId=' + this.encodeUrlBase64(releaseId.toString()))
    }

    getHref(): string {
        return window.location.href
    }

    setHref(href: string): void {
        window.location.href = href
    }

    encodeUrlBase64(value: string): string {
        return encodeURIComponent(btoa(value))
    }
}
