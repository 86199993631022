import { ContentContainer } from './content-container.model'
import { FileContext } from './file-context.model'

export class Attachment implements ContentContainer {
    constructor(
        public id?: number,
        public originalId?: number,
        public orderId?: number,
        public heading?: string,
        public textContent?: string,
        public linkedAttachments?: Attachment[],
        public fileContexts?: FileContext[]
    ) {
        if (!this.fileContexts) {
            this.fileContexts = []
        }
        if (!this.linkedAttachments) {
            this.linkedAttachments = []
        }
    }
}
