import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { RouterModule } from '@angular/router'

import { JhipsterSharedModule } from '../../shared'
import { HttpClientModule } from '@angular/common/http'
import {
    MaterialDetailComponent
} from '.'
import { materialRoutes } from './material.route'
import { MaterialService } from './material.service'
import { ReferenceSelectModule } from '../reference-select'
import { CommonBookModule } from '../common/common-book.module'

const ENTITY_STATES = [
    ...materialRoutes
]

@NgModule({
    imports: [
        JhipsterSharedModule,
        RouterModule.forChild(ENTITY_STATES),
        HttpClientModule,
        ReferenceSelectModule,
        CommonBookModule
    ],
    declarations: [
        MaterialDetailComponent
    ],
    entryComponents: [
        MaterialDetailComponent
    ],
    exports: [
        MaterialDetailComponent
    ],
    providers: [
        MaterialService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MaterialModule {}
