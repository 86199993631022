import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { SERVER_API_URL } from '../../app.constants'
import { ProfileInfo } from './profile-info.model'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { StateStorageService } from '../../shared/auth/state-storage.service'

export enum ProfileEnvironmentEnum {
    Lidl = 'lidl',
    Kaufland = 'kaufland'
}
@Injectable()
export class ProfileService {

    private profileInfoUrl = SERVER_API_URL + 'api/profile-info'

    constructor(private httpClient: HttpClient, private readonly stateStorageService: StateStorageService) {
    }

    getProfileInfo(): Observable<ProfileInfo> {
        return this.httpClient.get<ProfileInfo>(this.profileInfoUrl).pipe(
            map((res) => {
                const data = res
                const pi = new ProfileInfo()
                pi.activeProfiles = data.activeProfiles
                pi.ribbonEnv = data.ribbonEnv
                pi.inProduction = ((data.currentStage === 'kflprd') || (data.currentStage === 'ldlprd'))
                pi.swaggerEnabled = data.activeProfiles.indexOf('swagger') !== -1
                pi.lidl = data.lidl
                pi.currentStage = data.currentStage
                pi.backendVersion = data.backendVersion
                return pi
            }))
    }

    storeEnvironment(profile: ProfileInfo): void {
        this.stateStorageService.storeProfileEnvironment(profile.lidl ? ProfileEnvironmentEnum.Lidl : ProfileEnvironmentEnum.Kaufland)
    }
}
