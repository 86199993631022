import { getSelectors, RouterReducerState } from '@ngrx/router-store'
import { createFeatureSelector, createSelector, MemoizedSelector, Selector } from '@ngrx/store'
import { BookState } from '../book.state'

export const selectRouter = createFeatureSelector<BookState, RouterReducerState<any>>('router')

// We have to hack this in order to make the router selectors mockable with `.overrideSelector()` in the tests
function memoize<T, V>(selector: Selector<T, V>): MemoizedSelector<T, V> {
    return createSelector(selector, (state) => state)
}

const internalSelectors = getSelectors(selectRouter)

export const selectQueryParams = memoize(internalSelectors.selectQueryParams)
export const selectRouteParams = memoize(internalSelectors.selectRouteParams)
export const selectRouteData = memoize(internalSelectors.selectRouteData)
export const selectUrl = memoize(internalSelectors.selectUrl)
export const selectCurrentRoute = memoize(internalSelectors.selectCurrentRoute)

export const selectOverlayOutletParams = createSelector(selectRouter, (routerState) => {
    const state = routerState.state

    if (!state || !state.root || !state.root.children) {
        return undefined
    }

    const route = state.root.children.find((currentRoute) => currentRoute.outlet === 'overlay')

    if (route && route.firstChild) {
        return route.firstChild.params
    } else {
        return undefined
    }

})
