import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { OAuth2Service } from './oauth2.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *jhiHasAnyAuthority="'ROLE_ADMIN'">...</some-element>
 *
 *     <some-element *jhiHasAnyAuthority="['ROLE_ADMIN', 'ROLE_USER']">...</some-element>
 * ```
 */
@UntilDestroy()
@Directive({
    selector: '[jhiHasAnyAuthority]'
})
export class HasAnyAuthorityDirective {

    private authorities: string[]

    constructor(private readonly oauth2Service: OAuth2Service, private readonly templateRef: TemplateRef<any>, private readonly viewContainerRef: ViewContainerRef) {
    }

    @Input()
    set jhiHasAnyAuthority(value: string|string[]) {
        this.authorities = typeof value === 'string' ? [ <string> value ] : <string[]> value
        this.oauth2Service.hasAnyAuthority(this.authorities)
        .pipe(untilDestroyed(this))
        .subscribe((result) => {
            this.viewContainerRef.clear()
            if (result) {
                this.viewContainerRef.createEmbeddedView(this.templateRef)
            }
        })
    }

}
