import { Pipe, PipeTransform } from '@angular/core'

/**
 * Converts bytes to a specific unit.
 */
@Pipe({
    name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

    private readonly MB = 'MB'

    transform(bytes: number, units?: string): string {
        if (!bytes) {
            return ''
        }

        switch (units) {
            case this.MB:
                const size = bytes / Math.pow(1024, 2)
                return Math.round(size * 100) / 100 + ' ' + this.MB
            default:
                return bytes.toString(10)
        }
    }
}
