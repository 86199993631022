import { Routes } from '@angular/router'

import { UserRouteAccessService } from '../../shared'
import { CraftDetailComponent } from './craft-detail.component'
import { Roles } from '../../shared/auth/roles'

export const craftRoutes: Routes = [
    {
        path: 'craft/:id',
        component: CraftDetailComponent,
        data: {
            authorities: Roles.VIEWER
        },
        canActivate: [UserRouteAccessService]
    }
]
