export class MaterialGroupTableItem {
    constructor(
        public originalId?: number,
        public heading?: string,
    ) {
    }

    public getUrl(): string {
        return `material-group/${this.originalId}`
    }

    get hasEmptyHeading() {
        return !this.heading || this.heading.trim() === ''
    }
}
