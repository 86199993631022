import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { RouterModule } from '@angular/router'

import { JhipsterSharedModule } from '../../shared'
import { HttpClientModule } from '@angular/common/http'
import {
    MaterialGroupDetailComponent
} from '.'
import { MaterialGroupService } from './material-group.service'
import { materialGroupRoutes } from './material-group.route'
import { ReferenceSelectModule } from '../reference-select'
import { CommonBookModule } from '../common/common-book.module'

const ENTITY_STATES = [
    ...materialGroupRoutes
]

@NgModule({
    imports: [
        JhipsterSharedModule,
        RouterModule.forChild(ENTITY_STATES),
        HttpClientModule,
        ReferenceSelectModule,
        CommonBookModule
    ],
    declarations: [
        MaterialGroupDetailComponent
    ],
    entryComponents: [
        MaterialGroupDetailComponent
    ],
    exports: [
        MaterialGroupDetailComponent
    ],
    providers: [
        MaterialGroupService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MaterialGroupModule {}
